import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ConfigureSubAccountFormatDialog,
  SubAccountFormatData,
} from '../ConfigureSubAccountFormatDialog';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DataGridPro, GridCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid-pro';
import {
  changeFileMapping,
  changeFileTransformationScript,
  getFileCategoryScript,
  getFileContent,
} from '@services/api';
import {
  createFileSubCategoryMapping,
  getFileSubCategoryMappings,
  updateFileSubCategoryMapping,
} from '@services/api/fileSubCategoryMappings';
import {
  createFileSubCategoryTransformationScript,
  getFileSubCategoryTransformationScript,
} from '@services/api/fileSubCategoryTransformationScripts';
import { useApi, useDebounce, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentActions } from '@models/enums/AdjustmentActions';
import AlertCircleFilledIcon from '@assets/icons/dashboard/alert-circle-filled.svg';
import { ColumnMenu } from './components/ColumnMenu';
import { ContentTransformationResultStatuses } from '@models/enums/ContentTransformationResultStatuses';
import { CustomDialog } from '@components/CustomDialog';
import { FileCategoryFieldType } from '@models/enums/FileCategoryFieldType';
import { FileContentTypes } from '@models/constants/FileContentTypes';
import { FileSubCategoryMappingDelimiter } from '@models/enums/FileSubCategoryMappingDelimiter';
import { ICreateFileSubCategoryMappingData } from '@models/interfaces/additional/ICreateFileSubCategoryMappingData';
import { ICreateFileSubCategoryTransformationScriptData } from '@models/interfaces/additional/ICreateFileSubCategoryTransformationScriptData';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IFileSubCategoryMapping } from '@models/interfaces/entities/IFileSubCategoryMapping';
import { ILink } from '@models/interfaces/entities/ILink';
import { Loader } from '@components/Loader';
import { LogsViewer } from '@components/LogsViewer';
import Papa from 'papaparse';
import { PrepaymentSpeedsType } from '@models/enums/PrepaymentSpeedsType';
import { PreviewTableFooter } from './components/PreviewTableFooter';
import { ScriptUploader } from '@components/ScriptUploader';
import SettingsFilledIcon from '@assets/icons/dashboard/settings-filled.svg';
import SettingsIcon from '@assets/icons/dashboard/settings.svg';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import clsx from 'clsx';
import deepEqual from 'deep-equal';
import { getFileCategoryFields } from '@services/api/fileCategoryFields';
import moment from 'moment';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

enum MappingTabs {
  script = 0,
  mapping = 1,
  cleanup = 2,
}

const previewRowsCount = 30;

enum ImportRecordType {
  delimiter = 'Delimiter',
  srcType = 'SrcType',
  header = 'HeaderRow',
  map = 'ValueMap',
  glMap = 'ValueMapGL',
  subAccountFormat = 'SubActFmt',
  delinquentLoanThreshold = 'DelinqThresh',
  action = 'Action',
}

interface IStoredMapping {
  mapping: IFileSubCategoryMapping | null;
  loaded: boolean;
}

interface IImportedMapping {
  column: string;
  field: string;
  action?: AdjustmentActions;
  coefficient?: number;
}

interface ISavedMapping {
  column: string;
  fields: {
    id: string;
    action?: AdjustmentActions;
    coefficient?: number;
  }[];
}

interface IProps {
  file: IFile;
  fileCategory: IFileCategory;
  fileSubCategory: IFileSubCategory;
  open: boolean;
  onClose: (reload?: boolean) => void;
}

export interface IFormData {
  name: string;
  delimiter: FileSubCategoryMappingDelimiter;
  headerStartRow?: number;
  headerEndRow?: number;
  records: {
    columnName?: string;
    columnIndex: number;
    fields: {
      id: string;
      action?: AdjustmentActions;
      coefficient?: number;
      mode?: string;
    }[];
  }[];
  subAccountIdDefinitions: {
    priority: number;
    format: string[];
    rules: {
      fieldId: string;
      operation: string;
      value: string;
    }[];
  }[];
}

const subaccountIdFormatMappings = new Map([
  ['Pmnt Freq', 'Payment Frequency'],
  ['Margin', 'Rate Margin'],
  ['LT Cap', 'Lifetime Cap'],
  ['Per Cap', 'Periodic Cap'],
  ['LT Floor', 'Lifetime Floor'],
  ['Collateral', 'Collateral Code'],
  ['Purpose', 'Purpose Code'],
  ['Number', 'G/L Number'],
  ['Suffix', 'G/L Suffix'],
  ['Delinquent', 'Delinquent Loan'],
]);

const delay = 500;

export const MappingDialog = ({ file, fileCategory, fileSubCategory, open, onClose }: IProps) => {
  const { classes } = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileCategoryFields, setFileCategoryFields] = useState<IFileCategoryField[] | null>();
  const [importedMappings, setImportedMappings] = useState<IImportedMapping[] | null>();
  const [savedMappings, setSavedMappings] = useState<ISavedMapping[] | null>();
  const [currentMapping, setCurrentMapping] = useState<IStoredMapping>({
    loaded: false,
    mapping: null,
  });
  const [fileContent, setFileContent] = useState<File | null>();
  const [logs, setLogs] = useState<string>('');
  const [cleanupLogs, setCleanupLogs] = useState<string>('');
  const [columns, setColumns] = useState<string[]>([]);
  const [unusedColumnsVisibility, setUnusedColumnsVisibility] = useState(true);
  const [parsingInProgress, setParsingInProgress] = useState<boolean>(false);
  const [readingInProgress, setReadingInProgress] = useState<boolean>(false);
  const [data, setData] = useState<string[][]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rows, setRows] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [invalidFieldIds, setInvalidFieldIds] = useState<string[]>([]);
  const [cleanupScript, setCleanupScript] = useState<string>('');
  const [savedScript, setSavedScript] = useState<string | null>(null);
  const [currentScript, setCurrentScript] = useState<string>('');
  const [activeTab, setActiveTab] = useState<MappingTabs>(MappingTabs.script);

  const formDefaultData = useMemo<IFormData>(() => {
    return {
      delimiter: FileSubCategoryMappingDelimiter.comma,
      name: file.id,
      headerStartRow: 0,
      headerEndRow: 0,
      records: [],
      subAccountIdDefinitions: [],
    };
  }, [open, file]);

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const {
    fields: records,
    update: updateRecord,
    replace: replaceRecords,
  } = useFieldArray({
    name: 'records',
    control: form.control,
  });

  const { fields: definitions, replace: replaceDefinitions } = useFieldArray({
    name: 'subAccountIdDefinitions',
    control: form.control,
  });

  const {
    request: getFileCategoryFieldsRequest,
    data: getFileCategoryFieldsData,
    loading: getFileCategoryFieldsLoading,
  } = useApi(getFileCategoryFields, null, { handleErrors: true });

  const {
    request: updateFileSubCategoryMappingRequest,
    data: updateFileSubCategoryMappingData,
    loading: updateFileSubCategoryMappingLoading,
  } = useApi(updateFileSubCategoryMapping, null, { handleErrors: true });

  const {
    request: getFileSubCategoryMappingsRequest,
    data: getFileSubCategoryMappingsData,
    loading: getFileSubCategoryMappingsLoading,
  } = useApi(getFileSubCategoryMappings, null, {
    handleErrors: true,
    onCallback: (data) => {
      if (!data) return;
      if (file.fileSubCategoryMappingId) {
        setCurrentMapping({
          mapping: data.items.find((x) => x.id === file.fileSubCategoryMappingId) || null,
          loaded: true,
        });
      } else {
        setCurrentMapping({
          mapping: null,
          loaded: true,
        });
      }
    },
  });

  const {
    request: getFileSubCategoryTransformationScriptRequest,
    data: getFileSubCategoryTransformationScriptData,
    loading: getFileSubCategoryTransformationScriptLoading,
  } = useApi(getFileSubCategoryTransformationScript, null, {
    handleErrors: true,
  });

  const {
    request: getFileCategoryScriptRequest,
    data: getFileCategoryScriptData,
    loading: getFileCategoryScriptLoading,
  } = useApi(getFileCategoryScript, null, {
    handleErrors: true,
  });

  const {
    request: createAndChangeFileMappingRequest,
    data: createAndChangeFileMappingData,
    loading: createAndChangeFileMappingLoading,
  } = useApi(
    async (
      createFileSubCategoryMappingLink: ILink,
      changeFileMappingLink: ILink,
      data: ICreateFileSubCategoryMappingData,
    ) => {
      const mapping = await createFileSubCategoryMapping(
        createFileSubCategoryMappingLink.href,
        data,
      );
      await changeFileMapping(changeFileMappingLink.href, {
        mappingId: mapping.id,
      });

      return mapping;
    },
    null,
    {
      handleErrors: true,
    },
  );

  const {
    request: createAndChangeFileTransformationScriptRequest,
    data: createAndChangeFileTransformationScriptData,
    loading: createAndChangeFileTransformationScriptLoading,
  } = useApi(
    async (
      createFileSubCategoryTransformationScriptLink: ILink,
      changeFileTransformationScriptLink: ILink,
      data: ICreateFileSubCategoryTransformationScriptData,
    ) => {
      const transformationScript = await createFileSubCategoryTransformationScript(
        createFileSubCategoryTransformationScriptLink.href,
        data,
      );
      await changeFileTransformationScript(changeFileTransformationScriptLink.href, {
        transformationScriptId: transformationScript.id,
      });

      return transformationScript;
    },
    null,
    {
      handleErrors: true,
    },
  );

  const { request: getFileContentRequest, loading: getFileContentLoading } = useApi(
    getFileContent,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) setFileContent(data.content);
      },
    },
  );

  const getFileContentAsString = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);

      reader.readAsText(file);
    });
  };

  const { request: getFileContentCleanupLogsRequest, loading: getFileContentCleanupLogsLoading } =
    useApi(getFileContent, null, {
      handleErrors: true,
      onCallback: async (data) => {
        if (data) {
          const content = await getFileContentAsString(data.content);
          setCleanupLogs(content);
        }
      },
    });

  const {
    request: getFileContentTransformationLogsRequest,
    loading: getFileContentTransformationLogsLoading,
  } = useApi(getFileContent, null, {
    handleErrors: true,
    onCallback: async (data) => {
      if (data) {
        const content = await getFileContentAsString(data.content);
        setLogs(content);
      }
    },
  });

  const {
    request: getFileContentTransformationResultRequest,
    loading: getFileContentTransformationResultLoading,
  } = useApi(getFileContent, null, {
    handleErrors: true,
    onCallback: async (data) => {
      if (data) {
        saveAs(data.content, `result (${moment(new Date()).format('hh.mm.ss MM.DD.YYYY')}).csv`);
      }
    },
  });

  const watchHeaderStartRow = form.watch('headerStartRow');
  const watchHeaderEndRow = form.watch('headerEndRow');
  const watchDelimiter = form.watch('delimiter');

  const debouncedWatchHeaderStartRow = useDebounce(watchHeaderStartRow, delay);
  const debouncedWatchHeaderEndRow = useDebounce(watchHeaderEndRow, delay);
  const debouncedWatchDelimiter = useDebounce(watchDelimiter, delay);
  const debouncedImportedMappings = useDebounce(importedMappings, delay);
  const debouncedSavedMappings = useDebounce(savedMappings, delay);

  const onCloseConfigureSubAccountFormatDialog = (data?: SubAccountFormatData) => {
    if (!data) {
      setOpenDialog(false);
    } else {
      replaceDefinitions(data.subAccountIdDefinitions);
      setOpenDialog(false);
    }
  };

  const onConfigureSubAccountFormation = () => {
    setOpenDialog(true);
  };

  const onCancel = () => {
    onClose();
  };

  const onTabChanged = (tab: MappingTabs) => {
    setActiveTab(tab);
  };

  const onFormSubmit = async () => {
    const data = form.getValues();

    const unmappedRequiredFields = fileCategoryFields?.filter(
      (x) => x.required && !data.records.find((r) => r.fields.find((c) => c.id === x.id)),
    );
    setInvalidFieldIds(unmappedRequiredFields?.map((x) => x.id) || []);

    if (unmappedRequiredFields?.length) {
      toast.error('All the required fields should be mapped');
      return;
    }

    if (
      !currentMapping.mapping &&
      getFileSubCategoryMappingsData?.links[Actions.createMapping] &&
      file.links[Actions.changeMapping]
    ) {
      createAndChangeFileMappingRequest(
        getFileSubCategoryMappingsData.links[Actions.createMapping],
        file.links[Actions.changeMapping],
        data,
      );
    } else if (currentMapping.mapping?.links[Actions.update]) {
      updateFileSubCategoryMappingRequest(currentMapping.mapping.links[Actions.update].href, data);
    }
  };

  const onReset = () => {
    form.reset(formDefaultData);
  };

  const onImport: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const targetFile = target?.files ? target.files[0] : null;
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (!targetFile) {
      return;
    }
    const fileReader = new FileReader();

    fileReader.readAsText(targetFile);
    fileReader.onload = (e) => {
      const text = e.target?.result;
      if (text && typeof text === 'string') {
        const data = parseDMMappingFile(text);
        if (data) {
          form.setValue('delimiter', data.delimiter);
          form.setValue('headerStartRow', data.headerStartRow);
          form.setValue('headerEndRow', data.headerEndRow);
          replaceDefinitions([
            {
              format: data.subAccountFormat,
              priority: 0,
              rules: [],
            },
          ]);
          setImportedMappings(data.maps);
        }
      }
    };
  };

  const onChangeUnusedColumnsVisibility = (value: boolean) => {
    setUnusedColumnsVisibility(value);
  };

  const convertSmmToCpr = (value: number) => {
    return 100 * (1 - Math.pow(1 - value / 100, 12));
  };

  const convertFieldValue = (
    value: string | undefined,
    field: IFileCategoryField,
    adjustment?: { action: AdjustmentActions; coefficient: number },
    mode?: string,
  ) => {
    const trimmedValue = value !== undefined ? value.toString().trim() : '';
    if (trimmedValue === '') return trimmedValue;
    switch (field.type) {
      case FileCategoryFieldType.decimal:
      case FileCategoryFieldType.integer: {
        const numericValue = Number(trimmedValue);
        if ((!adjustment && mode !== PrepaymentSpeedsType.smm) || Number.isNaN(numericValue)) {
          return trimmedValue;
        }
        switch (adjustment?.action) {
          case AdjustmentActions.multiply: {
            let adjustedValue = numericValue * adjustment.coefficient;
            if (mode === PrepaymentSpeedsType.smm) {
              adjustedValue = convertSmmToCpr(adjustedValue);
            }
            return adjustedValue.toFixed(field.type === FileCategoryFieldType.decimal ? 2 : 0);
          }
          case AdjustmentActions.divide: {
            let adjustedValue = numericValue / adjustment.coefficient;
            if (mode === PrepaymentSpeedsType.smm) {
              adjustedValue = convertSmmToCpr(adjustedValue);
            }
            return adjustedValue.toFixed(field.type === FileCategoryFieldType.decimal ? 2 : 0);
          }
          default: {
            let adjustedValue = numericValue;
            if (mode === PrepaymentSpeedsType.smm) {
              adjustedValue = convertSmmToCpr(numericValue);
            }
            return adjustedValue.toFixed(field.type === FileCategoryFieldType.decimal ? 2 : 0);
          }
        }
      }
      default:
        return trimmedValue;
    }
  };

  const onUploadScript = () => {
    if (
      fileSubCategory.links[Actions.createTransformationScript] &&
      file.links[Actions.changeTransformationScript]
    ) {
      const data = { script: currentScript };
      createAndChangeFileTransformationScriptRequest(
        fileSubCategory.links[Actions.createTransformationScript],
        file.links[Actions.changeTransformationScript],
        data,
      );
    }
  };

  const onDownloadResult = (link: ILink) => {
    getFileContentTransformationResultRequest(link.href);
  };

  const parseDMMappingFile = (text: string) => {
    const results = Papa.parse<string[]>(text, { delimiter: ',' });

    // parse source type
    const [srcTypeRecord] = getImportRecord(results.data, ImportRecordType.srcType);
    if (!srcTypeRecord) {
      toast.error('Source type is not specified');
      return;
    }
    let srcType = 1;
    if (srcTypeRecord) {
      srcType = +srcTypeRecord[1];
      if (Number.isNaN(srcType)) {
        toast.error('Source type is invalid');
        return;
      }
    }

    // parse sub account format
    const [subAccountFormatRecord] = getImportRecord(
      results.data,
      ImportRecordType.subAccountFormat,
    );
    if (!subAccountFormatRecord) {
      toast.error('Sub account format is not specified');
      return;
    }
    let [, ...subAccountFormat] = subAccountFormatRecord;
    subAccountFormat = subAccountFormat.filter((x) => !!x);

    // transform subaccount format data
    if (!subAccountFormat.length) {
      subAccountFormat = ['Identifier'];
    }

    if (fileCategoryFields) {
      subAccountFormat = subAccountFormat.map((x) => {
        const fieldName = subaccountIdFormatMappings.get(x) || x;
        const field = fileCategoryFields?.find((x) => x.name === fieldName);
        return field?.displayName || fieldName;
      });
    }

    // parse delinquent loan threshold
    const [delinquentLoanThresholdRecord] = getImportRecord(
      results.data,
      ImportRecordType.delinquentLoanThreshold,
    );
    let delinquentLoanThreshold = 0;
    if (delinquentLoanThresholdRecord) {
      delinquentLoanThreshold = +delinquentLoanThresholdRecord[1];
      if (Number.isNaN(delinquentLoanThreshold)) {
        toast.error('Delinquent loan threshold is invalid');
        return;
      }
    }
    // parse delimiter
    const [delimiterRecord] = getImportRecord(results.data, ImportRecordType.delimiter);
    if (!delimiterRecord) {
      toast.error('Delimiter is not specified');
      return;
    }
    const delimiter = parseDelimiter(delimiterRecord[2]);
    if (!delimiter) {
      toast.error('Delimiter is invalid');
      return;
    }
    // parse header
    const [headerRecord] = getImportRecord(results.data, ImportRecordType.header);
    if (!headerRecord) {
      toast.error('Header is not specified');
      return;
    }
    const headerStartRow = +headerRecord[1];
    const headerEndRow = +headerRecord[2];
    // parse actions
    let adjustments: {
      field: string;
      action: string;
      coefficient: number;
    }[] = [];
    const actionRecords = getImportRecord(results.data, ImportRecordType.action);
    if (actionRecords.length) {
      adjustments = actionRecords
        .filter((ar) => !!ar[1] && !!ar[2] && !!ar[3] && !Number.isNaN(ar[3]))
        .map((ar) => ({
          field: ar[1].trim(),
          action: ar[2],
          coefficient: Number(ar[3]),
        }));
    }
    // parse maps
    let maps: {
      column: string;
      field: string;
      action?: AdjustmentActions;
      coefficient?: number;
    }[] = [];
    if (srcType === 1) {
      const glMapRecords = getImportRecord(results.data, ImportRecordType.glMap);
      if (!glMapRecords.length) {
        toast.error('Maps are not specified');
        return;
      }
      maps = glMapRecords
        .filter((mr) => !!mr[1] && !!mr[6])
        .flatMap((mr) => {
          const result = [];
          for (let i = 6; i < mr.length; i++) {
            if (mr[i] === '-1') break;
            const field = mr[i].trim();
            const adjustment = adjustments.find((x) => x.field === field);
            result.push({
              column:
                headerStartRow === 0 && headerEndRow === 0
                  ? transformImportedColumnName(mr[1].trim())
                  : mr[1].trim(),
              field,
              action: adjustment?.action as AdjustmentActions | undefined,
              coefficient: adjustment?.coefficient,
            });
          }
          return result;
        });
    } else {
      const mapRecords = getImportRecord(results.data, ImportRecordType.map);
      if (!mapRecords.length) {
        toast.error('Maps are not specified');
        return;
      }
      maps = mapRecords
        .filter((mr) => !!mr[1] && !!mr[5])
        .map((mr) => {
          const field = mr[5].trim();
          const adjustment = adjustments.find((x) => x.field === field);
          return {
            column:
              headerStartRow === 0 && headerEndRow === 0
                ? transformImportedColumnName(mr[1].trim())
                : mr[1].trim(),
            field,
            action: adjustment?.action as AdjustmentActions | undefined,
            coefficient: adjustment?.coefficient,
          };
        });
    }

    return {
      subAccountFormat,
      delinquentLoanThreshold,
      delimiter,
      headerStartRow,
      headerEndRow,
      maps,
    };
  };

  const transformImportedColumnName = (column: string) => {
    const match = column.match('Field([0-9]{1,2})');
    if (match) {
      const index = +match[1];
      return `Column ${index}`;
    } else {
      return column;
    }
  };

  const getImportRecord = (data: string[][], recordType: ImportRecordType) => {
    return data.filter((x) => x[0] === recordType);
  };

  const onUpdateRecord = (fieldId: string, columnIndex: number) => {
    if (columnIndex !== -1) {
      setInvalidFieldIds((ids) => ids.filter((x) => x !== fieldId));
    } else {
      const category = fileCategoryFields?.find((x) => x.id === fieldId);
      if (category?.required) {
        setInvalidFieldIds((ids) => [...ids, fieldId]);
      }
    }
    const prevRecordIndex = records.findIndex((r) => r.fields.find((x) => x.id === fieldId));
    if (prevRecordIndex !== -1) {
      updateRecord(prevRecordIndex, {
        ...records[prevRecordIndex],
        fields: records[prevRecordIndex].fields.filter((x) => x.id !== fieldId),
      });
    }
    if (columnIndex !== -1) {
      const updatedRecordIndex = records.findIndex((r) => r.columnIndex === columnIndex);
      if (updatedRecordIndex !== -1) {
        updateRecord(updatedRecordIndex, {
          ...records[updatedRecordIndex],
          fields: Array.from(new Set([{ id: fieldId }, ...records[updatedRecordIndex].fields])),
        });
      }
    }
  };

  const onAdjustmentUpdated = (
    fieldId: string,
    columnIndex: number,
    action: AdjustmentActions,
    coefficient?: number,
  ) => {
    const updatedRecordIndex = records.findIndex((r) => r.columnIndex === columnIndex);
    if (updatedRecordIndex !== -1) {
      const existingFields = records[updatedRecordIndex].fields.filter((x) => x.id !== fieldId);
      const previousField = records[updatedRecordIndex].fields.find((x) => x.id === fieldId);

      const updatedField = {
        id: fieldId,
        action: action !== AdjustmentActions.none ? action : undefined,
        coefficient: action !== AdjustmentActions.none ? coefficient : undefined,
        mode: previousField?.mode,
      };

      updateRecord(updatedRecordIndex, {
        ...records[updatedRecordIndex],
        fields: [...existingFields, updatedField],
      });
    }
  };

  const onModeUpdated = (fieldId: string, columnIndex: number, mode?: string) => {
    const updatedRecordIndex = records.findIndex((r) => r.columnIndex === columnIndex);
    if (updatedRecordIndex !== -1) {
      const existingFields = records[updatedRecordIndex].fields.filter((x) => x.id !== fieldId);
      const previousField = records[updatedRecordIndex].fields.find((x) => x.id === fieldId);

      const updatedField = {
        id: fieldId,
        mode,
        action: previousField?.action,
        coefficient: previousField?.coefficient,
      };

      updateRecord(updatedRecordIndex, {
        ...records[updatedRecordIndex],
        fields: [...existingFields, updatedField],
      });
    }
  };

  const onScriptUploaded = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const content = event.target.result as string;
        setCurrentScript(content);
      }
    };
    reader.readAsText(file);
  };

  const getCellsRange = useCallback((ws: XLSX.WorkSheet) => {
    const regex = /^([A-Z]+)(\d+)$/;
    let maxLetters = 'A';
    let maxNumber = 1;
    const cells = Object.keys(ws).filter((x) => !x.startsWith('!'));
    cells.forEach((cell) => {
      const matches = cell.match(regex);
      if (matches) {
        const letters = matches[1];
        const number = parseInt(matches[2]);
        if (
          maxLetters.length < letters.length ||
          (maxLetters.length === letters.length && maxLetters < letters)
        )
          maxLetters = letters;
        if (maxNumber < number) maxNumber = number;
      }
    });
    return `A1:${maxLetters}${maxNumber}`;
  }, []);

  const readFile = async () => {
    if (!fileContent) return;
    const delimiter = watchDelimiter;

    setData([]);
    setColumns([]);
    setRows([]);
    replaceRecords([]);
    setInvalidFieldIds([]);

    if (FileContentTypes.excel.includes(file.contentType)) {
      setReadingInProgress(true);

      const buffer = await fileContent.arrayBuffer();
      const wb = XLSX.read(buffer, {
        type: 'buffer',
        sheets: 0,
        cellNF: false,
        cellText: false,
        cellDates: true,
      });
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];

      ws['!ref'] = getCellsRange(ws);

      const csvData = XLSX.utils.sheet_to_csv(ws, {
        dateNF: 'MM"/"DD"/"YYYY',
        blankrows: true,
      });

      Papa.parse<string[]>(csvData, {
        delimiter: ',',
        worker: true,
        error() {
          toast.error('Failed to parse the extracted csv data');
          setReadingInProgress(false);
        },
        complete({ data }) {
          setData(data);
          setReadingInProgress(false);
        },
      });
    } else if (!FileContentTypes.binary.includes(file.contentType)) {
      setReadingInProgress(true);
      Papa.parse<string[]>(fileContent, {
        delimiter: getDelimiterValue(delimiter),
        worker: true,
        error() {
          toast.error('Failed to parse the plain text file');
          setReadingInProgress(false);
        },
        complete({ data }) {
          setData(data);
          setReadingInProgress(false);
        },
      });
    } else {
      toast.error('Failed to parse the file of this type');
    }
  };

  const parseFileData = (headerStartRow: number, headerEndRow: number) => {
    if (!data.length) return;
    setParsingInProgress(true);
    let updatedColumns: string[] = [];
    if (!headerStartRow || !headerEndRow) {
      const columnsCount = data[0]?.length || 0;
      for (let idx = 0; idx < columnsCount; idx++) {
        updatedColumns.push(`Column ${idx + 1}`);
      }
    } else {
      for (let i = headerStartRow - 1; i <= headerEndRow - 1; i++) {
        const row = data[i];
        if (row) {
          for (let idx = 0; idx < row.length; idx++) {
            if (updatedColumns[idx]) {
              updatedColumns[idx] += row[idx];
            } else {
              updatedColumns[idx] = row[idx];
            }
          }
        }
      }
    }
    updatedColumns = updatedColumns.map((c, i) => c?.trim() || `Column ${i + 1}`);

    const rows = data.slice(headerEndRow).map((r) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const item: any = {};
      updatedColumns.forEach((c, idx) => {
        item[`c${idx}`] = r[idx];
      });
      return item;
    });

    if (columns.toString() !== updatedColumns.toString()) {
      const updatedRecords = updatedColumns.map((c, i) => ({
        columnName: c,
        columnIndex: i,
        fields: [],
      }));
      replaceRecords(updatedRecords);
      setInvalidFieldIds([]);
    }

    setColumns(updatedColumns);
    setRows(rows);
    setParsingInProgress(false);
  };

  const getDelimiterValue = (d: FileSubCategoryMappingDelimiter) => {
    switch (d) {
      case FileSubCategoryMappingDelimiter.comma:
        return ',';
      case FileSubCategoryMappingDelimiter.semicolon:
        return ';';
      case FileSubCategoryMappingDelimiter.tab:
        return '\t';
      case FileSubCategoryMappingDelimiter.space:
        return ' ';
      case FileSubCategoryMappingDelimiter.pipe:
        return '|';
    }
  };

  const parseDelimiter = (d: string) => {
    switch (d) {
      case ',':
        return FileSubCategoryMappingDelimiter.comma;
      case ';':
        return FileSubCategoryMappingDelimiter.semicolon;
      case '\t':
        return FileSubCategoryMappingDelimiter.tab;
      case ' ':
        return FileSubCategoryMappingDelimiter.space;
      case '|':
        return FileSubCategoryMappingDelimiter.pipe;
    }
  };

  useEffect(() => {
    if (open) {
      loadInitialData();
    } else {
      setLogs('');
      setFileContent(null);
      setData([]);
      setColumns([]);
      setRows([]);
      replaceRecords([]);
      setImportedMappings(null);
      setSavedMappings(null);
      setCurrentMapping({ loaded: false, mapping: null });
      setInvalidFieldIds([]);
      setUnusedColumnsVisibility(true);
      form.reset(formDefaultData);

      setSavedScript(null);
      setCurrentScript('');
    }
  }, [open]);

  const loadInitialMappingData = () => {
    setData([]);
    setColumns([]);
    setRows([]);
    replaceRecords([]);
    setInvalidFieldIds([]);
    if (fileSubCategory.links[Actions.getMappings]) {
      getFileSubCategoryMappingsRequest(fileSubCategory.links[Actions.getMappings].href);
    }
    if (fileCategory.links[Actions.getFields]) {
      getFileCategoryFieldsRequest(fileCategory.links[Actions.getFields].href);
    }
    if (
      file.links[Actions.getTransformationResultContentPreview] &&
      file.fileSubCategoryTransformationScriptId
    ) {
      getFileContentRequest(file.links[Actions.getTransformationResultContentPreview].href);
    } else if (
      file.links[Actions.getContentPreview] &&
      !file.fileSubCategoryTransformationScriptId
    ) {
      getFileContentRequest(file.links[Actions.getContentPreview].href);
    }
  };

  const loadTransformationLogs = () => {
    if (file.links[Actions.getTransformationResultLogs]) {
      getFileContentTransformationLogsRequest(file.links[Actions.getTransformationResultLogs].href);
    }
  };

  const loadCleanupLogs = () => {
    if (file.links[Actions.getCleanupResultLogs]) {
      getFileContentCleanupLogsRequest(file.links[Actions.getCleanupResultLogs].href);
    }
  };

  const loadInitialData = () => {
    if (file.links[Actions.getTransformationScript]) {
      getFileSubCategoryTransformationScriptRequest(
        file.links[Actions.getTransformationScript].href,
      );
    }
    if (fileCategory.links[Actions.getScript]) {
      getFileCategoryScriptRequest(fileCategory.links[Actions.getScript].href);
    }
  };

  useUpdateEffect(() => {
    if (getFileSubCategoryTransformationScriptData) {
      setCurrentScript(getFileSubCategoryTransformationScriptData.script);
      setSavedScript(getFileSubCategoryTransformationScriptData.script);
    }
  }, [getFileSubCategoryTransformationScriptData]);

  useUpdateEffect(() => {
    if (getFileCategoryScriptData) {
      setCleanupScript(getFileCategoryScriptData);
    }
  }, [getFileCategoryScriptData]);

  useUpdateEffect(() => {
    if (getFileCategoryFieldsData) {
      setFileCategoryFields(getFileCategoryFieldsData.items);
    }
  }, [getFileCategoryFieldsData]);

  useUpdateEffect(() => {
    if (createAndChangeFileMappingData) {
      setCurrentMapping({ loaded: true, mapping: createAndChangeFileMappingData });
    }
  }, [createAndChangeFileMappingData]);

  useUpdateEffect(() => {
    if (createAndChangeFileTransformationScriptData) {
      setSavedScript(createAndChangeFileTransformationScriptData.script);
      setLogs('');
    }
  }, [createAndChangeFileTransformationScriptData]);

  useUpdateEffect(() => {
    if (updateFileSubCategoryMappingData) {
      setCurrentMapping({ loaded: true, mapping: updateFileSubCategoryMappingData });
    }
  }, [updateFileSubCategoryMappingData]);

  useUpdateEffect(() => {
    if (columns.length && debouncedImportedMappings?.length && fileCategoryFields?.length) {
      const updatedRecords = form.getValues('records');

      for (let i = 0; i < updatedRecords.length; i++) {
        updatedRecords[i].fields = [];
      }

      debouncedImportedMappings.forEach((x) => {
        const fileCategoryField = fileCategoryFields.find(
          (f) => f.name.toLowerCase() === x.field.toLowerCase(),
        );
        const columnIndex = columns.findIndex((c) => c.toLowerCase() === x.column.toLowerCase());
        if (columnIndex !== -1) {
          const index = updatedRecords.findIndex((r) => r.columnIndex === columnIndex);
          if (index !== -1) {
            updatedRecords[index].columnName = columns[columnIndex];
            if (fileCategoryField?.id) {
              updatedRecords[index].fields = [
                ...updatedRecords[index].fields,
                { id: fileCategoryField.id, action: x.action, coefficient: x.coefficient },
              ];
            }
          }
        }
      });
      replaceRecords(updatedRecords);
      setInvalidFieldIds([]);
      setImportedMappings(null);
    }
  }, [columns, debouncedImportedMappings, fileCategoryFields, form]);

  useUpdateEffect(() => {
    if (columns.length && debouncedSavedMappings?.length) {
      const updatedRecords = form.getValues('records');
      debouncedSavedMappings.forEach((x) => {
        const columnIndex = columns.findIndex((c) => c.toLowerCase() === x.column.toLowerCase());
        if (columnIndex !== -1) {
          const index = records.findIndex((r) => r.columnIndex === columnIndex);
          if (index !== -1) {
            updatedRecords[index].columnName = columns[columnIndex];
            updatedRecords[index].fields = x.fields;
          }
        }
      });
      replaceRecords(updatedRecords);
      setInvalidFieldIds([]);
      setSavedMappings(null);
    }
  }, [columns, debouncedSavedMappings, form]);

  useEffect(() => {
    if (!currentMapping.loaded || !fileContent) return;
    if (currentMapping.mapping) {
      form.setValue('delimiter', currentMapping.mapping.delimiter);
      form.setValue('headerStartRow', currentMapping.mapping.headerStartRow);
      form.setValue('headerEndRow', currentMapping.mapping.headerEndRow);
      replaceDefinitions(currentMapping.mapping.subAccountIdDefinitions);
      setSavedMappings(
        currentMapping.mapping.records.map((r) => ({
          column: r.columnName || '',
          fields: r.fields,
        })),
      );
    }
    readFile();
  }, [currentMapping.loaded, currentMapping.mapping?.id, fileContent]);

  const getLabelByFieldId = (fieldId: string) => {
    const field = fileCategoryFields?.find((x) => x.id === fieldId);
    return `${field?.displayName || field?.name || ''} ${field?.required ? '*' : ''}`;
  };

  const columnNameOptions = useMemo(() => {
    const headerStartRow = watchHeaderStartRow ? +watchHeaderStartRow : 0;
    const headerEndRow = watchHeaderEndRow ? +watchHeaderEndRow : 0;
    // case when we are using the real column names from file
    if (headerStartRow && headerEndRow) {
      const sortedColumnOptions = columns.map((item, i) => ({ value: i, label: item }));
      sortedColumnOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

      return [{ value: -1, label: 'None' }, ...sortedColumnOptions];
    } else {
      return [
        { value: -1, label: 'None' },
        ...columns.map((item, i) => ({ value: i, label: item })),
      ];
    }
  }, [columns, watchHeaderStartRow, watchHeaderEndRow]);

  const getColumn = useCallback(
    (
      c: string,
      i: number,
      field?: IFileCategoryField,
      adjustment?: { action: AdjustmentActions; coefficient: number },
      mode?: string,
    ) => {
      const adjustmentLabel =
        !!adjustment && adjustment.action !== AdjustmentActions.none
          ? `${adjustment.action === AdjustmentActions.divide ? '/' : '*'} ${
              adjustment.coefficient
            }`
          : undefined;

      const disableColumnMenu =
        field?.type !== FileCategoryFieldType.decimal &&
        field?.type !== FileCategoryFieldType.integer;

      const calculateWidth = (content: string) => {
        let totalWidth = 0;
        const charWidthLowercase = 7.5;
        const charWidthUppercaseSpecial = 10;

        for (const char of content) {
          if (char >= 'a' && char <= 'z') {
            totalWidth += charWidthLowercase;
          } else {
            totalWidth += charWidthUppercaseSpecial;
          }
        }

        return totalWidth;
      };

      return {
        field: `c${i}_${field?.id || 'default'}`,
        headerClassName: field ? classes.selectedCell : undefined,
        type: 'string',
        sortable: false,
        filterable: false,
        hideable: false,
        width: Math.max(
          calculateWidth(c) +
            30 +
            (!disableColumnMenu ? 40 : 0) +
            (mode === PrepaymentSpeedsType.smm ? 40 : 0) +
            (adjustmentLabel ? calculateWidth(adjustmentLabel) : 0),
          30 + calculateWidth(field?.displayName || field?.name || 'Unassigned') * 0.9,
          100,
        ),
        disableColumnMenu,
        renderCell: (params) => {
          const value = params.row[`c${i}`] || '';
          const hasValueToConvert =
            (field?.type === FileCategoryFieldType.integer ||
              field?.type === FileCategoryFieldType.decimal) &&
            (!!adjustment || mode === PrepaymentSpeedsType.smm);

          if (!hasValueToConvert) {
            return value;
          }
          const convertedValue = convertFieldValue(value, field, adjustment, mode);
          if (convertedValue !== value) {
            return (
              <div>
                {convertedValue} <small>({value})</small>
              </div>
            );
          }
          return value;
        },
        renderHeader: (params) => {
          const apiRef = useGridApiContext();

          const handleMenuOpen: React.MouseEventHandler<HTMLButtonElement> = (event) => {
            event.preventDefault();
            event.stopPropagation();
            apiRef.current.showColumnMenu(params.colDef.field);
          };

          return (
            <Box>
              <Box className={classes.colHeaderTopSection}>
                <Box className={classes.columnTitleSection}>
                  <Typography variant='subtitle1'>{c}</Typography>
                  {adjustmentLabel && (
                    <Typography variant='subtitle1' className={classes.adjustmentLabel}>
                      {adjustmentLabel}
                    </Typography>
                  )}
                </Box>

                {!disableColumnMenu && (
                  <Box className={classes.columnTitleSection}>
                    {mode === PrepaymentSpeedsType.smm && (
                      <Typography variant='overline' className={classes.infoText}>
                        SMM
                      </Typography>
                    )}
                    <IconButton aria-label='menu' onClick={handleMenuOpen} size='small'>
                      <img src={SettingsIcon} alt='settings' />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Divider className={classes.colHeaderDivider} />
              <Typography variant='body2' className={classes.colSubHeader}>
                {field?.displayName || field?.name || 'Unassigned'}
              </Typography>
            </Box>
          );
        },
      } as GridColDef;
    },
    [],
  );

  const previewTableColumns = useMemo<GridColDef[]>(() => {
    const basicColumns = columns.flatMap<GridColDef | undefined>((c, i) => {
      const recordFields = records.find((r) => r.columnIndex === i)?.fields;
      const mappedFields = fileCategoryFields?.filter((x) =>
        recordFields?.find((c) => c.id === x.id),
      );
      const hide = !unusedColumnsVisibility && !mappedFields?.length;
      if (hide) return undefined;
      if (!mappedFields || !mappedFields.length) {
        return [getColumn(c, i, undefined, undefined, undefined)];
      } else {
        return mappedFields.map((x) => {
          const recordField = recordFields?.find((c) => c.id === x.id);
          const adjustment =
            !!recordField?.action &&
            recordField?.coefficient !== undefined &&
            recordField?.coefficient !== null
              ? { action: recordField.action, coefficient: recordField.coefficient }
              : undefined;
          const mode = recordField?.mode;
          return getColumn(c, i, x, adjustment, mode);
        });
      }
    });
    return basicColumns.filter((x) => !!x) as GridColDef[];
  }, [columns, records, unusedColumnsVisibility]);

  const previewTableRows = useMemo(
    () => rows.slice(0, previewRowsCount).map((p) => ({ ...p, id: uuidv4() })),
    [rows],
  );

  const fields = useMemo(() => {
    const sortedFileCategoryFields = fileCategoryFields || [];
    sortedFileCategoryFields.sort((a, b) => {
      const aName = a.displayName || a.name;
      const bName = b.displayName || b.name;
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
    return sortedFileCategoryFields.map((x) => ({
      name: x.displayName || x.name,
      fieldId: x.id,
      columnIndex: records.findIndex((r) => r.fields.find((c) => c.id === x.id)),
    }));
  }, [fileCategoryFields, records]);

  const fieldsWithPrepaymentSpeedSelectionEnabled = useMemo(() => {
    const prepaymentSpeedConfigurationFields = [
      '-300',
      '-200',
      '-100',
      'Flat',
      '+100',
      '+200',
      '+300',
    ];
    return (fileCategoryFields || [])
      .filter((x) => prepaymentSpeedConfigurationFields.includes(x.displayName || x.name))
      .map((x) => x.id);
  }, [fileCategoryFields]);

  const saveDisabled = useMemo(() => {
    return (
      (!currentMapping.mapping &&
        (!getFileSubCategoryMappingsData?.links[Actions.createMapping] ||
          !file.links[Actions.changeMapping])) ||
      (currentMapping.mapping && !currentMapping.mapping.links[Actions.update]) ||
      false
    );
  }, [getFileSubCategoryMappingsData, currentMapping, file]);

  useUpdateEffect(() => {
    readFile();
  }, [debouncedWatchDelimiter]);

  useUpdateEffect(() => {
    if (watchHeaderStartRow !== undefined && watchHeaderEndRow !== undefined) {
      parseFileData(+watchHeaderStartRow, +watchHeaderEndRow);
    }
  }, [data, debouncedWatchHeaderStartRow, debouncedWatchHeaderEndRow]);

  useUpdateEffect(() => {
    if (watchHeaderStartRow !== undefined) {
      const hsr = +watchHeaderStartRow;
      const her = watchHeaderEndRow ? +watchHeaderEndRow : 0;
      if (her < hsr) {
        form.setValue('headerEndRow', hsr);
      }
    }
  }, [watchHeaderStartRow, watchHeaderStartRow]);

  const showLoader = useLoader(
    getFileCategoryFieldsLoading,
    getFileSubCategoryMappingsLoading,
    getFileContentLoading,
    getFileContentTransformationLogsLoading,
    getFileContentCleanupLogsLoading,
    createAndChangeFileMappingLoading,
    updateFileSubCategoryMappingLoading,
    getFileSubCategoryTransformationScriptLoading,
    createAndChangeFileTransformationScriptLoading,
    getFileContentTransformationResultLoading,
    getFileCategoryScriptLoading,
    parsingInProgress,
    readingInProgress,
  );

  const hasTransformationProcessingInProgress = useMemo(() => {
    return (
      file.fileSubCategoryTransformationScriptId &&
      file.contentTransformationResultStatus === ContentTransformationResultStatuses.inProgress
    );
  }, [file]);

  const hasTransformationProcessingFailed = useMemo(() => {
    return (
      file.fileSubCategoryTransformationScriptId &&
      file.contentTransformationResultStatus === ContentTransformationResultStatuses.failure
    );
  }, [file]);

  const hasTransformationProcessingCompleted = useMemo(() => {
    return (
      file.fileSubCategoryTransformationScriptId &&
      file.contentTransformationResultStatus === ContentTransformationResultStatuses.complete
    );
  }, [file]);

  const hasCleanupProcessingFailed = useMemo(() => {
    return file.contentCleanupResultStatus === ContentTransformationResultStatuses.failure;
  }, [file]);

  const hasCleanupProcessingCompleted = useMemo(() => {
    return file.contentCleanupResultStatus === ContentTransformationResultStatuses.complete;
  }, [file]);

  useEffect(() => {
    if (open && !hasTransformationProcessingInProgress && !hasTransformationProcessingFailed) {
      loadInitialMappingData();
    }
  }, [hasTransformationProcessingInProgress, hasTransformationProcessingFailed, open]);

  useEffect(() => {
    if (open && (hasTransformationProcessingCompleted || hasTransformationProcessingFailed)) {
      loadTransformationLogs();
    }
  }, [hasTransformationProcessingCompleted, hasTransformationProcessingFailed, open]);

  useEffect(() => {
    if (open && (hasCleanupProcessingCompleted || hasCleanupProcessingFailed)) {
      loadCleanupLogs();
    } else {
      setCleanupLogs('');
    }
  }, [hasCleanupProcessingCompleted, hasCleanupProcessingFailed, open]);

  const hasUnsavedChanges = useMemo(() => {
    if (!currentMapping.loaded) return false;
    if (!currentMapping.mapping) return true;
    const {
      delimiter,
      headerStartRow,
      headerEndRow,
      records: currentMappingRecords,
      subAccountIdDefinitions: currentSubAccountIdDefinitions,
    } = currentMapping.mapping;
    const storedData = {
      delimiter,
      headerStartRow: headerStartRow || 0,
      headerEndRow: headerEndRow || 0,
      records: currentMappingRecords.map(({ columnName, columnIndex, fields }) => ({
        columnName,
        columnIndex,
        fields: fields.map((x) => {
          if (
            x.action !== undefined &&
            x.action !== AdjustmentActions.none &&
            x.coefficient !== undefined
          ) {
            return x;
          } else {
            return { id: x.id };
          }
        }),
      })),
      definitions: currentSubAccountIdDefinitions.map(({ format, priority, rules }) => ({
        format,
        priority,
        rules,
      })),
    };
    const actualData = {
      delimiter: watchDelimiter,
      headerStartRow: watchHeaderStartRow ? +watchHeaderStartRow : 0,
      headerEndRow: watchHeaderEndRow ? +watchHeaderEndRow : 0,
      records: records.map(({ columnName, columnIndex, fields }) => ({
        columnName,
        columnIndex,
        fields: fields.map((x) => {
          if (
            x.action !== undefined &&
            x.action !== AdjustmentActions.none &&
            x.coefficient !== undefined
          ) {
            return x;
          } else {
            return { id: x.id };
          }
        }),
      })),
      definitions: definitions.map(({ format, priority, rules }) => ({ format, priority, rules })),
    };
    return !deepEqual(storedData, actualData);
  }, [
    currentMapping.loaded,
    currentMapping.mapping,
    watchDelimiter,
    watchHeaderStartRow,
    watchHeaderEndRow,
    records,
    definitions,
  ]);

  return (
    <CustomDialog
      title='Configure mapping'
      subtitle={file.sourceFileName ? `${file.sourceFileName} > ${file.name}` : file.name}
      onClose={onCancel}
      open={open}
      maxWidth='xl'
      fullWidth
      actions={
        (activeTab === MappingTabs.script && (
          <>
            <Button
              variant='contained'
              size='large'
              color='info'
              disabled={
                !currentScript ||
                currentScript === savedScript ||
                !file.links[Actions.changeTransformationScript] ||
                !fileSubCategory.links[Actions.createTransformationScript]
              }
              onClick={onUploadScript}
            >
              Upload Script
            </Button>

            <Button
              variant='contained'
              size='large'
              color='info'
              disabled={!file.links[Actions.getTransformationResultContent]}
              onClick={
                file.links[Actions.getTransformationResultContent]
                  ? () => onDownloadResult(file.links[Actions.getTransformationResultContent])
                  : undefined
              }
            >
              Download Result
            </Button>
          </>
        )) ||
        (activeTab === MappingTabs.mapping && (
          <>
            <Button
              variant='contained'
              size='large'
              color='info'
              disabled={!file.links[Actions.getDataExtractionResultContent]}
              onClick={
                file.links[Actions.getDataExtractionResultContent]
                  ? () => onDownloadResult(file.links[Actions.getDataExtractionResultContent])
                  : undefined
              }
            >
              Download Result
            </Button>
            <Tooltip
              title={
                !definitions.length
                  ? 'SubAccount Id is required. Please define the SubAccount Id format before saving.'
                  : undefined
              }
            >
              <Box>
                <Button
                  type='submit'
                  form='form'
                  variant='contained'
                  size='large'
                  color='info'
                  disabled={saveDisabled || !hasUnsavedChanges || !definitions.length}
                >
                  Save
                </Button>
              </Box>
            </Tooltip>
          </>
        )) ||
        (activeTab === MappingTabs.cleanup && (
          <>
            <div />

            <Button
              variant='contained'
              size='large'
              color='info'
              disabled={!file.links[Actions.getCleanupResultContent]}
              onClick={
                file.links[Actions.getCleanupResultContent]
                  ? () => onDownloadResult(file.links[Actions.getCleanupResultContent])
                  : undefined
              }
            >
              Download Result
            </Button>
          </>
        ))
      }
    >
      <Box className={classes.root}>
        <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
          <Tab
            id='script-tab'
            aria-controls='script-tab-panel'
            label={<Typography variant='subtitle1'>Script</Typography>}
            value={MappingTabs.script}
          />
          <Tab
            id='mappings-tab'
            aria-controls='mappings-tab-panel'
            label={<Typography variant='subtitle1'>Mappings</Typography>}
            value={MappingTabs.mapping}
          />
          <Tab
            id='cleanup-tab'
            aria-controls='cleanup-tab-panel'
            label={<Typography variant='subtitle1'>Cleanup</Typography>}
            value={MappingTabs.cleanup}
          />
        </Tabs>
        <Box
          className={classes.tabContainer}
          role='tabpanel'
          hidden={activeTab !== MappingTabs.script}
          id='script-tab-panel'
          data-testid='script-tab-panel'
          aria-labelledby='script-tab'
        >
          <Box className={classes.scriptContainer}>
            <Box className={classes.scriptUploadingContainer}>
              {file.links[Actions.changeTransformationScript] &&
                fileSubCategory.links[Actions.createTransformationScript] && (
                  <ScriptUploader onScriptUploaded={onScriptUploaded} maxFileSize={20} />
                )}
              <SyntaxHighlighter language='python' style={vs} showLineNumbers>
                {currentScript}
              </SyntaxHighlighter>
            </Box>
            <Box className={classes.scriptLogsContainer}>
              <LogsViewer logs={logs || 'No logs here...'} />
            </Box>
          </Box>
        </Box>
        <Box
          className={classes.tabContainer}
          role='tabpanel'
          hidden={activeTab !== MappingTabs.mapping}
          id='mappings-tab-panel'
          data-testid='mappings-tab-panel'
          aria-labelledby='mappings-tab'
        >
          <Box className={classes.mappingsContainer}>
            {hasTransformationProcessingInProgress ? (
              <Box className={classes.infoCard}>
                <img
                  src={SettingsFilledIcon}
                  alt='spinner'
                  className={clsx([classes.spinnerIcon])}
                />
                One moment as we retrieve the contents of your file...
              </Box>
            ) : hasTransformationProcessingFailed ? (
              <Box className={classes.infoCard}>
                <img
                  src={AlertCircleFilledIcon}
                  alt='failure'
                  className={clsx([classes.failureIcon])}
                />
                Failed to transform your file used uploaded transformation script. The
                transformation script needs to be reuploaded.
              </Box>
            ) : (
              <DataGridPro
                className={classes.previewTable}
                rows={previewTableRows}
                columns={previewTableColumns}
                density='standard'
                getCellClassName={(params: GridCellParams) =>
                  params.colDef.headerClassName?.toString() || ''
                }
                showCellVerticalBorder
                showColumnVerticalBorder
                slots={{
                  footer: () => (
                    <PreviewTableFooter
                      onChangeUnusedColumnsVisibility={onChangeUnusedColumnsVisibility}
                      unusedColumnsVisibility={unusedColumnsVisibility}
                    />
                  ),
                  columnMenu: (props) => (
                    <ColumnMenu
                      onAdjustmentUpdated={onAdjustmentUpdated}
                      onModeUpdated={onModeUpdated}
                      records={records.map(({ columnIndex, fields }) => ({ columnIndex, fields }))}
                      fieldsWithPrepaymentSpeedSelectionEnabled={
                        fieldsWithPrepaymentSpeedSelectionEnabled
                      }
                      {...props}
                    />
                  ),
                }}
              />
            )}
            <form
              id='form'
              onSubmit={form.handleSubmit(() => {
                onFormSubmit();
              })}
              noValidate
              className={classes.formContainer}
            >
              <Card className={classes.formCard}>
                <Box className={classes.controls}>
                  {file.links[Actions.importMapping]?.href && (
                    <Button size='small' color='primary' component='label' variant='contained'>
                      Import
                      <input type='file' hidden ref={fileInputRef} onChange={onImport} />
                    </Button>
                  )}
                  <Button size='small' color='primary' variant='outlined' onClick={onReset}>
                    Clear
                  </Button>
                  <Button
                    size='small'
                    color='primary'
                    variant='outlined'
                    onClick={onConfigureSubAccountFormation}
                  >
                    SubAccount&nbsp;ID
                  </Button>
                </Box>
                {!FileContentTypes.excel.includes(file.contentType) && (
                  <Box className={classes.mt24}>
                    <Controller
                      name={'delimiter'}
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant='outlined' fullWidth>
                          <InputLabel id='delimiter-select-label'>Delimiter</InputLabel>
                          <Select
                            labelId='delimiter-select-label'
                            fullWidth
                            value={value}
                            label='Delimiter'
                            onChange={onChange}
                          >
                            <MenuItem value={FileSubCategoryMappingDelimiter.comma}>Comma</MenuItem>
                            <MenuItem value={FileSubCategoryMappingDelimiter.semicolon}>
                              Semicolon
                            </MenuItem>
                            <MenuItem value={FileSubCategoryMappingDelimiter.space}>Space</MenuItem>
                            <MenuItem value={FileSubCategoryMappingDelimiter.tab}>Tab</MenuItem>
                            <MenuItem value={FileSubCategoryMappingDelimiter.pipe}>Pipe</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Box>
                )}
                <Box className={clsx([classes.twoColumnsGrid, classes.mt24])}>
                  <Controller
                    name={'headerStartRow'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type='number'
                        inputProps={{ min: 0 }}
                        fullWidth
                        value={value}
                        variant='outlined'
                        label='Header start row'
                        onChange={onChange}
                        autoComplete='off'
                      />
                    )}
                  />
                  <Controller
                    name={'headerEndRow'}
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type='number'
                        inputProps={{ min: watchHeaderStartRow }}
                        fullWidth
                        value={value}
                        variant='outlined'
                        label='Header end row'
                        onChange={onChange}
                        autoComplete='off'
                      />
                    )}
                  />
                </Box>
              </Card>
              <Card className={clsx([classes.formCard, classes.controlsCard])}>
                <Box className={classes.columnsList}>
                  {fields?.map((item, index) => (
                    <Box key={index}>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className={
                          item.columnIndex !== -1 ? classes.assignedField : classes.unassignedField
                        }
                      >
                        <InputLabel id={`field-${item.fieldId}-select-label`}>
                          {getLabelByFieldId(item.fieldId)}
                        </InputLabel>
                        <Select
                          error={invalidFieldIds.includes(item.fieldId)}
                          labelId={`field-${item.fieldId}-select-label`}
                          fullWidth
                          value={item.columnIndex}
                          label={getLabelByFieldId(item.fieldId)}
                          onChange={(e) => onUpdateRecord(item.fieldId, +e.target.value)}
                        >
                          {columnNameOptions.map((o) => (
                            <MenuItem key={o.value} value={o.value}>
                              {o.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ))}
                </Box>
              </Card>
            </form>
          </Box>
        </Box>
        <Box
          className={classes.tabContainer}
          role='tabpanel'
          hidden={activeTab !== MappingTabs.cleanup}
          id='cleanup-tab-panel'
          data-testid='cleanup-tab-panel'
          aria-labelledby='cleanup-tab'
        >
          <Box className={classes.scriptContainer}>
            <Box className={classes.scriptUploadingContainer}>
              <SyntaxHighlighter language='python' style={vs} showLineNumbers>
                {cleanupScript}
              </SyntaxHighlighter>
            </Box>
            <Box className={classes.scriptLogsContainer}>
              <LogsViewer logs={cleanupLogs || 'No logs here...'} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Loader show={showLoader} fixed={false} />
      <ConfigureSubAccountFormatDialog
        open={openDialog}
        onClose={onCloseConfigureSubAccountFormatDialog}
        fields={fileCategoryFields || []}
        subAccountIdDefinitions={definitions}
      />
    </CustomDialog>
  );
};
