import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useApi, useLoader } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import { AdjustmentsTab } from './components/AdjustmentsTab';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { PremiumsTab } from './components/PremiumsTab';
import { SectionAdjustmentsTab } from './components/SectionAdjustmentsTab';
import { SourceBalancesTab } from './components/SourceBalancesTab';
import { getAdjustmentDefinitions } from '@services/api';
import useStyles from './styles';

export enum DetailsTabs {
  sourceBalance = 0,
  glAdjustments = 1,
  fees = 2,
  premiums = 3,
  sectionAdjustments = 4,
}

interface IProps {
  accountId: string;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  project: IProject;
  tab: DetailsTabs;
  hideFeesTab?: boolean;
  hidePremiumsTab?: boolean;
}

export const AccountDetails = ({
  accountId,
  adjustments,
  accounts,
  project,
  tab,
  hideFeesTab,
  hidePremiumsTab,
}: IProps) => {
  const { classes } = useStyles();

  const [adjustmentDefinitions, setAdjustmentDefinitions] = useState<IAdjustmentDefinition[]>([]);
  const [activeTab, setActiveTab] = useState<DetailsTabs>(tab);

  const { request: getAdjustmentDefinitionsRequest, loading: getAdjustmentDefinitionsLoading } =
    useApi(getAdjustmentDefinitions, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinitions(data.items);
        }
      },
    });

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href)
      getAdjustmentDefinitionsRequest(
        project.links[Actions.getAdjustmentDefinitions].href,
        AdjustmentOperation.Initial,
        undefined,
        undefined,
        accountId,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href]);

  const onTabChanged = (tab: DetailsTabs) => {
    setActiveTab(tab);
  };

  const initialAdjustments = useMemo<IAdjustment[]>(
    () =>
      adjustments.filter(
        (x) => x.operation === AdjustmentOperation.Initial && x.accountId === accountId,
      ),
    [adjustments, accountId],
  );

  const glAdjustments = useMemo<IAdjustment[]>(
    () =>
      adjustments.filter(
        (x) =>
          (x.operation === AdjustmentOperation.Add ||
            x.operation === AdjustmentOperation.Subtract ||
            x.operation === AdjustmentOperation.Match) &&
          x.accountId === accountId,
      ),
    [adjustments, accountId],
  );

  const feesAdjustments = useMemo<IAdjustment[]>(
    () =>
      adjustments.filter(
        (x) => x.operation === AdjustmentOperation.Fees && x.accountId === accountId,
      ),
    [adjustments, accountId],
  );

  const premiumsAdjustments = useMemo<IAdjustment[]>(
    () =>
      adjustments.filter(
        (x) => x.operation === AdjustmentOperation.Premium && x.accountId === accountId,
      ),
    [adjustments, accountId],
  );

  const sectionAdjustments = useMemo<IAdjustment[]>(
    () =>
      adjustments.filter(
        (x) => x.operation === AdjustmentOperation.Balance && x.accountId === accountId,
      ),
    [adjustments, accountId],
  );

  const showLoader = useLoader(getAdjustmentDefinitionsLoading);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
            <Tab
              id='source-balance-tab'
              aria-controls='source-balance-tab-panel'
              label={<Typography variant='subtitle2'>Source Balance</Typography>}
              value={DetailsTabs.sourceBalance}
            />
            <Tab
              id='gl-adjustments-tab'
              aria-controls='gl-adjustments-tab-panel'
              label={<Typography variant='subtitle2'>GL Adjustments</Typography>}
              value={DetailsTabs.glAdjustments}
            />
            {!hideFeesTab && (
              <Tab
                id='fees-tab'
                aria-controls='fees-tab-panel'
                label={<Typography variant='subtitle2'>Fees</Typography>}
                value={DetailsTabs.fees}
              />
            )}
            {!hidePremiumsTab && (
              <Tab
                id='premiums-tab'
                aria-controls='premiums-tab-panel'
                label={<Typography variant='subtitle2'>Premiums/Discounts</Typography>}
                value={DetailsTabs.premiums}
              />
            )}
            <Tab
              id='section-adjustments-tab'
              aria-controls='section-adjustments-tab-panel'
              label={<Typography variant='subtitle2'>Section Adjustments</Typography>}
              value={DetailsTabs.sectionAdjustments}
            />
          </Tabs>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.sourceBalance}
            id='source-balance-tab-panel'
            aria-labelledby='source-balance-tab'
          >
            <SourceBalancesTab
              adjustments={initialAdjustments}
              adjustmentDefinitions={adjustmentDefinitions}
            />
          </Box>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.glAdjustments}
            id='gl-adjustments-tab-panel'
            aria-labelledby='gl-adjustments-tab'
          >
            <AdjustmentsTab adjustments={glAdjustments} showOperation />
          </Box>
          {!hideFeesTab && (
            <Box
              role='tabpanel'
              hidden={activeTab !== DetailsTabs.fees}
              id='fees-tab-panel'
              aria-labelledby='fees-tab'
            >
              <AdjustmentsTab adjustments={feesAdjustments} />
            </Box>
          )}
          {!hidePremiumsTab && (
            <Box
              role='tabpanel'
              hidden={activeTab !== DetailsTabs.premiums}
              id='premiums-tab-panel'
              aria-labelledby='premiums-tab'
            >
              <PremiumsTab adjustments={premiumsAdjustments} />
            </Box>
          )}
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.sectionAdjustments}
            id='section-adjustments-tab-panel'
            aria-labelledby='section-adjustments-tab'
          >
            <SectionAdjustmentsTab adjustments={sectionAdjustments} accounts={accounts} />
          </Box>
        </Box>
      </Box>
      <Loader show={showLoader} fixed={false} />
    </>
  );
};
