import { Box, Container, Tooltip } from '@mui/material';
import { formatBalance, formatPercentage } from '../../utils';
import { getAdjustmentDefinition, getAdjustments } from '@services/api';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import useStyles from './styles';

export interface IBalanceAdjustmentDetails {
  adjustmentDefinitionId?: string;
  balancingAccountName: string;
  balancingAccountId: string;
  balanceSheetTotal: number;
  accountsTotal: number;
  adjustment: number;
  adjustments: IAdjustment[];
}

interface IProps {
  details: IBalanceAdjustmentDetails;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
}

export const BalanceAdjustmentDetails = ({
  details,
  accounts,
  category,
  project,
  types,
}: IProps) => {
  const { classes } = useStyles();

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceOperations: string[] = [
            AdjustmentOperation.Initial,
            AdjustmentOperation.Manual,
            AdjustmentOperation.Add,
            AdjustmentOperation.Subtract,
            AdjustmentOperation.Match,
            AdjustmentOperation.Fees,
            AdjustmentOperation.Premium,
          ];

          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation),
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const detailAdjustmentItems = useMemo(() => {
    const balanceOperations: string[] = [
      AdjustmentOperation.Initial,
      AdjustmentOperation.Manual,
      AdjustmentOperation.Add,
      AdjustmentOperation.Subtract,
      AdjustmentOperation.Match,
      AdjustmentOperation.Fees,
      AdjustmentOperation.Premium,
    ];

    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      return {
        account,
        balance: getAccountTotalBalance(x.accountId, balanceOperations, balanceAdjustments),
        adjustment: x.amount,
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return targetAccountsData;
  }, [detailsAdjustments, accounts, types, balanceAdjustments, adjustmentDefinition]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter(
        (x) => types.includes(x.accountType.type) && x.summaryCode !== 1 && !x.isBalancing,
      ),
    [accounts],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href && details.adjustmentDefinitionId)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = adjustmentDefinition.targets.map((x) => x.id);

      if (project.links[Actions.getAdjustments]?.href) {
        getAdjustmentsRequest(
          project.links[Actions.getAdjustments].href,
          [
            AdjustmentOperation.Initial,
            AdjustmentOperation.Manual,
            AdjustmentOperation.Add,
            AdjustmentOperation.Subtract,
            AdjustmentOperation.Match,
            AdjustmentOperation.Fees,
            AdjustmentOperation.Premium,
            AdjustmentOperation.Balance,
          ],
          usedAccountIds,
        );
      }
    }
  }, [filteredAccounts, adjustmentDefinition, project.links[Actions.getAdjustments]?.href]);

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.balance, 0),
    [detailAdjustmentItems],
  );

  const showLoader = useLoader(getAdjustmentDefinitionLoading, getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          <Box className={classes.grid}>
            <Box className={classes.colSpan4} />
            <Box className={clsx([classes.header, classes.textRight])}>Section Adjustment</Box>
            <Box className={classes.spacer} />
            <Box className={classes.header}>Balance Sheet Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.balanceSheetTotal)}
            </Box>
            <Box className={clsx([classes.header, classes.col1])}>Accounts Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.accountsTotal)}
            </Box>
            <Box className={classes.spacer} />
            <Box className={clsx([classes.header, 'alignItemsEnd'])}>Apply Adjustment To</Box>
            <Box
              className={clsx([classes.header, classes.col3, classes.textRight, 'alignItemsEnd'])}
            >
              Balance
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Portion of Total
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Adjustment
            </Box>

            {detailAdjustmentItems.map((x) => (
              <>
                <Box
                  className={clsx([classes.pl30, classes.col1, !x.hasCorrectType && classes.red])}
                >
                  {x.hasCorrectType ? (
                    x.account?.accountName || 'Unknown'
                  ) : (
                    <Tooltip
                      title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                    >
                      <Box className={clsx([classes.flex, classes.red])}>
                        <img src={AlertIcon} alt='alert' />
                        {x.account?.accountName || 'Unknown'}
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                <Box
                  className={clsx([
                    classes.col3,
                    classes.textRight,
                    !x.hasCorrectType && classes.red,
                  ])}
                >
                  {formatBalance(x.balance)}
                </Box>
                <Box className={clsx([classes.textRight, !x.hasCorrectType && classes.red])}>
                  {formatPercentage(
                    detailAdjustmentsTotalAccountsBalance
                      ? x.balance / detailAdjustmentsTotalAccountsBalance
                      : 1 / detailAdjustmentItems.length,
                  )}
                </Box>

                <Box className={clsx([classes.green, classes.textRight])}>
                  {formatBalance(x.adjustment)}
                </Box>
              </>
            ))}

            <Box className={clsx([classes.pl30, classes.header, classes.col1])}>Total</Box>
            <Box className={clsx([classes.header, classes.col3, classes.textRight])}>
              {formatBalance(detailAdjustmentsTotalAccountsBalance)}
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatPercentage(detailAdjustmentItems.length ? 1 : 0)}
            </Box>
            <Box className={classes.spacer} />
            <Box className={classes.header}>Total Adjustment</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.adjustment)}
            </Box>
            <Box
              className={clsx([
                classes.header,
                detailAdjustmentsTotalAdjustment ? classes.green : classes.red,
                classes.col5,
                classes.textRight,
              ])}
            >
              {formatBalance(detailAdjustmentsTotalAdjustment)}
            </Box>
          </Box>
        </Container>
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
