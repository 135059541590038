import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    height: 36,
    padding: 8,
    justifyContent: 'space-between',
  },
  controlsContainer: {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
