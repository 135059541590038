import { useCallback, useState } from 'react';

export const formatBalance = (value: number) => {
  const formatted = `$${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.abs(value))}`;
  return value < 0 ? `(${formatted})` : formatted;
};

export const formatPercentage = (value: number) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formatted;
};

export const useTableExpand = () => {
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  const onToggleTableExpand = useCallback(() => {
    setIsTableExpanded((prev) => !prev);
  }, []);

  return { isTableExpanded, onToggleTableExpand };
};
