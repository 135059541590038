import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    maxBlockSize: '60vh',
    height: 'calc(100vh - 272px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  marginTop24: {
    marginTop: 24,
  },
  marginLeft16: {
    marginLeft: 16,
  },
  tabContainer: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  scriptUploadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    overflowY: 'hidden',

    '& pre': {
      flexGrow: 1,
      '&::-webkit-scrollbar': {
        width: 8,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: t.palette.custom.dark[67],
        borderRadius: 4,
      },
    },
  },
}));

export default useStyles;
