import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Typography,
} from '@mui/material';
import { getAccounts, getAdjustmentDefinitions, getTypeCodes } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import ChevronDownIcon from '@assets/icons/accordion/chevron-down.svg';
import { ExistingTypeCodesGroup } from '../ExistingTypeCodesGroup';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IBalance } from '@models/interfaces/entities/IBalance';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { Loader } from '@components/Loader';
import { NewTypeCodeStatuses } from '@models/enums/NewTypeCodeStatuses';
import { NewTypeCodesGroup } from '../NewTypeCodesGroup';
import { getBalance } from '@services/api/balances';
import useStyles from './styles';

export interface IProps {
  id?: string;
  project: IProject;
  item: IFileCategory;
  readonly?: boolean;
  subCategories: IFileSubCategory[];
  files: IFile[];
  oldFiles: IFile[];
  balance?: IBalance;
}

export const FileCategoryTypeCodes = ({
  id,
  project,
  item,
  readonly,
  subCategories,
  files,
  oldFiles,
  balance,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [ignoredTypeCodes, setIgnoredTypeCodes] = useState<ITypeCode[]>([]);
  const [initialAdjustmentDefinitions, setInitialAdjustmentDefinitions] = useState<
    IAdjustmentDefinition[]
  >([]);
  const [newTypeCodes, setNewTypeCodes] = useState<ITypeCode[]>([]);
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [detailedBalance, setDetailedBalance] = useState<IBalance>();

  const isInvestmentsCategory = useMemo(() => item.name === 'Investments', [item.name]);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  const {
    request: getTypeCodesRequest,
    data: getTypeCodesData,
    loading: getTypeCodesLoading,
  } = useApi(getTypeCodes, null, { handleErrors: true });

  const {
    request: getAdjustmentDefinitionsRequest,
    data: getAdjustmentDefinitionsData,
    loading: getAdjustmentDefinitionsLoading,
  } = useApi(getAdjustmentDefinitions, null, { handleErrors: true });

  const {
    request: getBalanceRequest,
    data: getBalanceData,
    loading: getBalanceLoading,
  } = useApi(getBalance, null, { handleErrors: true });

  const getNewTypeCodes = useCallback(() => {
    if (project.links[Actions.getTypeCodes]) {
      getTypeCodesRequest(project.links[Actions.getTypeCodes].href, item.id, true);
    }
  }, [project, item]);

  const getIgnoredTypeCodes = useCallback(() => {
    if (project.links[Actions.getTypeCodes]) {
      getTypeCodesRequest(
        project.links[Actions.getTypeCodes].href,
        item.id,
        undefined,
        true,
        false,
      );
    }
  }, [project, item]);

  const mappedSubCategories = useMemo(() => {
    if (item.subCategoriesDisabled) return [];
    const result = subCategories.map((category) => {
      const file = files.find((x) => x.fileSubCategoryId === category.id);
      const oldFile = oldFiles.find((x) => x.fileSubCategoryId === category.id);
      return {
        category,
        file,
        oldFile,
      };
    });
    result.sort((a, b) => {
      const aNameLowercased = a.oldFile?.name.toLowerCase();
      const bNameLowercased = b.oldFile?.name.toLowerCase();
      if (!aNameLowercased) {
        return 1;
      }
      if (!bNameLowercased) {
        return -1;
      }
      return aNameLowercased > bNameLowercased ? 1 : bNameLowercased > aNameLowercased ? -1 : 0;
    });
    return result.filter(({ oldFile, file }) => oldFile || file);
  }, [subCategories, files, oldFiles, item.subCategoriesDisabled]);

  const enabledMappedSubCategoriesCount = useMemo(
    () => mappedSubCategories.filter(({ category, file }) => !category.disabled || file).length,
    [mappedSubCategories],
  );

  const formatBalance = useCallback((value: number) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)}`;
  }, []);

  const onLoadAdjustmentDefinitions = () => {
    if (project.links[Actions.getAdjustmentDefinitions]) {
      getAdjustmentDefinitionsRequest(
        project.links[Actions.getAdjustmentDefinitions].href,
        'initial',
        item.id,
      );
    }
  };

  const onNewTypeCodeUpdated = (typeCode: ITypeCode) => {
    setNewTypeCodes((prev) => prev.map((x) => (x.typeCode !== typeCode.typeCode ? x : typeCode)));
    // getNewTypeCodes();
  };

  useEffect(() => {
    if (expanded && balance?.links[Actions.self]) {
      getBalanceRequest(balance.links[Actions.self].href);
    }
  }, [balance, expanded]);

  useEffect(() => {
    if (!isInvestmentsCategory && expanded && project.links[Actions.getTypeCodes]) {
      getNewTypeCodes();
    }
  }, [project, item, expanded, isInvestmentsCategory]);

  useEffect(() => {
    if (!isInvestmentsCategory && expanded && project.links[Actions.getAdjustmentDefinitions]) {
      getAdjustmentDefinitionsRequest(
        project.links[Actions.getAdjustmentDefinitions].href,
        'initial',
        item.id,
      );
    }
  }, [project, item, expanded, isInvestmentsCategory]);

  useEffect(() => {
    if (!isInvestmentsCategory && expanded && project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
  }, [project, expanded, isInvestmentsCategory]);

  useUpdateEffect(() => {
    if (getAdjustmentDefinitionsData) {
      setInitialAdjustmentDefinitions(getAdjustmentDefinitionsData.items);
    }
  }, [getAdjustmentDefinitionsData]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  useUpdateEffect(() => {
    if (getTypeCodesData) {
      if (getTypeCodesData.isNew) {
        setNewTypeCodes(getTypeCodesData.items);
      } else {
        setIgnoredTypeCodes(getTypeCodesData.items);
      }
    }
  }, [getTypeCodesData]);

  useUpdateEffect(() => {
    if (getBalanceData) {
      setDetailedBalance(getBalanceData);
    }
  }, [getBalanceData]);

  const totalBalance = useMemo(() => {
    const bal = detailedBalance || balance;
    if (!bal) {
      return 0;
    }
    return bal.totalBalance - (bal.ignoredBalance || 0);
  }, [detailedBalance, balance]);

  const getCommonDescription = useCallback((descriptions: string[]) => {
    if (!descriptions.length) return '';
    const first = descriptions[0];
    let commonPart = '';

    for (let i = 0; i < first.length; i++) {
      const char = first[i];
      if (descriptions.every((desc) => desc[i] === char)) {
        commonPart += char;
      } else {
        break;
      }
    }

    return commonPart;
  }, []);

  const groupedInitialAdjustmentDefinitions = useMemo(() => {
    const uniqueDefinitions: Array<{
      code: string;
      name: string;
      descriptions: string[];
      accountIds: string[];
    }> = [];

    initialAdjustmentDefinitions.forEach((x) => {
      if (x.targets.length) {
        const code = x.source.value.title || '';
        const name = x.tierLimits.length
          ? accounts.find((a) => a.id === x.targets[0].id)?.tierGroup || x.targets[0].accountName
          : x.targets[0].accountName;
        const accountId = x.targets[0].id;

        const existing = uniqueDefinitions.find((def) => def.code === code && def.name === name);

        if (existing) {
          existing.descriptions.push(x.description);
          existing.accountIds.push(accountId);
        } else {
          uniqueDefinitions.push({
            code,
            name,
            descriptions: [x.description],
            accountIds: [accountId],
          });
        }
      }
    });

    const result = uniqueDefinitions.map((d) =>
      Object.freeze({
        code: d.code,
        name: d.name,
        description: getCommonDescription(d.descriptions),
        accountIds: d.accountIds,
      }),
    );

    return result;
  }, [initialAdjustmentDefinitions, accounts]);

  const newTypeCodeItems = useMemo(
    () =>
      newTypeCodes.map((x) => {
        let status = NewTypeCodeStatuses.none;
        let accountId: string | undefined = undefined;
        let description = '';
        let additional = '';

        if (x.isKnown) {
          if (x.isUsed) {
            const initAdjustmentDefinition = groupedInitialAdjustmentDefinitions.find(
              (ad) => ad.code === x.typeCode,
            );
            if (initAdjustmentDefinition) {
              status = NewTypeCodeStatuses.mappedToExistingAccount;
              accountId = initAdjustmentDefinition.accountIds[0];
              description = initAdjustmentDefinition.description;
              additional = initAdjustmentDefinition.name;
            }
          } else {
            status = NewTypeCodeStatuses.ignored;
            description = x.description;
            additional = x.ignoreReason || '';
          }
        }

        return {
          code: x.typeCode,
          balance: x.balance,
          status,
          description,
          additional,
          accountId,
        };
      }),

    [newTypeCodes, groupedInitialAdjustmentDefinitions],
  );

  const usedTypeCodes = useMemo(() => {
    return groupedInitialAdjustmentDefinitions.map(({ code, name, description }) => ({
      code,
      accountName: name,
      description,
    }));
  }, [groupedInitialAdjustmentDefinitions]);

  const unusedTypeCodes = useMemo(
    () =>
      ignoredTypeCodes.map((x) => ({
        code: x.typeCode,
        description: x.description,
        ignoreReason: x.ignoreReason || '',
      })),

    [ignoredTypeCodes],
  );

  console.log(unusedTypeCodes);

  const showLoader = useLoader(
    getAccountsLoading,
    getTypeCodesLoading,
    getAdjustmentDefinitionsLoading,
    getTypeCodesLoading,
    getBalanceLoading,
  );

  return (
    <Accordion
      id={id}
      className={classes.root}
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
      data-testid='file-category-type-codes'
    >
      <AccordionSummary
        expandIcon={<img alt='arrow down' data-testid='expand-icon' src={ChevronDownIcon} />}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <Box className={classes.title}>
          <Typography variant='h6'>{item.name}</Typography>
          <Typography component='div' variant='caption'>
            ({files.length}
            {!item.subCategoriesDisabled && !readonly
              ? ` of ${enabledMappedSubCategoriesCount}`
              : ''}
            {` file${files.length !== 1 ? 's' : ''}`})
          </Typography>
        </Box>
        <Box className={classes.balanceLabel}>
          <Typography variant='subtitle2'>Total Balance (Used and New Type Codes)</Typography>
          <Typography variant='subtitle2'>{formatBalance(totalBalance)}</Typography>
        </Box>
        {!expanded && <Loader size={20} show={showLoader} fixed={false} />}
      </AccordionSummary>
      <AccordionDetails>
        <Card className={classes.card} data-testid='content-section'>
          {isInvestmentsCategory ? (
            <Box className={classes.messageContainer}>
              <Typography variant='h6'>
                c. myers will handle your investment mapping and will reach out with any questions
              </Typography>
            </Box>
          ) : (
            <Box className={classes.groupsList}>
              <NewTypeCodesGroup
                categoryId={item.id}
                accounts={accounts}
                typeCodes={newTypeCodeItems}
                onUpdated={onNewTypeCodeUpdated}
                updateLink={getTypeCodesData?.links[Actions.updateNewTypeCode]?.href}
                getSamplesLink={project.links[Actions.getTypeCodeSamples]?.href}
                isGL={item.name.includes('GL')}
              />
              <ExistingTypeCodesGroup
                title='Existing Type Codes'
                typeCodes={usedTypeCodes}
                onToggle={(val) => val && onLoadAdjustmentDefinitions()}
                gridColumns={['Type Code', 'Type Code Description', 'Model Account']}
                totalBalance={detailedBalance?.usedBalance || 0}
              />
              <ExistingTypeCodesGroup
                title='Unused Type Codes'
                typeCodes={unusedTypeCodes}
                onToggle={(val) => val && getIgnoredTypeCodes()}
                gridColumns={['Type Code', 'Type Code Description', 'Reason']}
                totalBalance={detailedBalance?.ignoredBalance || 0}
              />
            </Box>
          )}
        </Card>
      </AccordionDetails>
      {expanded && <Loader size={expanded ? 100 : 20} show={showLoader} fixed={false} />}
    </Accordion>
  );
};
