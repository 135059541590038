import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    padding: 16,
    borderRadius: 10,
    borderColor: t.palette.custom.dark[30],
    borderStyle: 'solid',
    borderWidth: 2,
    position: 'relative',
  },

  autocomplete: {
    flexGrow: 1,
  },
  ruleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    paddingRight: 100,
  },
  readonlyRuleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    paddingRight: 100,
  },
  rulePrefix: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'space-between',
  },
  formatContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  readonlyFormatContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  controls: {
    padding: 16,
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  textHighlighted: {
    color: t.palette.custom.error[100],
    fontWeight: 600,
  },
}));

export default useStyles;
