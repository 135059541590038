import { Box, Button, Typography } from '@mui/material';
import { DefinitionCard, ISubAccountIdDefinition } from './components/DefinitionCard';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomDialog } from '@components/CustomDialog';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface SubAccountFormatData {
  subAccountIdDefinitions: {
    priority: number;
    format: string[];
    rules: {
      fieldId: string;
      operation: string;
      value: string;
    }[];
  }[];
}

export interface IProps {
  subAccountIdDefinitions: {
    priority: number;
    format: string[];
    rules: {
      fieldId: string;
      operation: string;
      value: string;
    }[];
  }[];
  fields: IFileCategoryField[];
  open: boolean;
  onClose: (data?: SubAccountFormatData) => void;
}

export const ConfigureSubAccountFormatDialog = ({
  fields,
  subAccountIdDefinitions,
  open,
  onClose,
}: IProps) => {
  const { classes } = useStyles();

  const defaultDefinitionData = useMemo<ISubAccountIdDefinition>(
    () => ({
      id: uuidv4(),
      format: [''],
      rules: [],
    }),
    [],
  );

  const [data, setData] = useState<ISubAccountIdDefinition[]>([]);
  const [definitionsInEditMode, setDefinitionsInEditMode] = useState<string[]>([]);
  const [definitionsToRemoveOnDiscard, setDefinitionsToRemoveOnDiscard] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      if (subAccountIdDefinitions.length) {
        setData(
          subAccountIdDefinitions
            .slice()
            .sort((a, b) => a.priority - b.priority)
            .map((definition) => ({
              ...definition,
              id: uuidv4(),
            })),
        );
        setDefinitionsInEditMode([]);
        setDefinitionsToRemoveOnDiscard([]);
      } else {
        setData([defaultDefinitionData]);
        setDefinitionsInEditMode([defaultDefinitionData.id]);
        setDefinitionsToRemoveOnDiscard([defaultDefinitionData.id]);
      }
    } else {
      setData([]);
      setDefinitionsInEditMode([]);
      setDefinitionsToRemoveOnDiscard([]);
    }
  }, [open]);

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    onClose({
      subAccountIdDefinitions: data.map((x, index) => ({
        ...x,
        priority: index,
      })),
    });
  };

  const onAddDefinition = () => {
    const id = uuidv4();

    setData((prevData) => {
      const newDefinition = {
        id,
        format: [''],
        rules: [
          {
            fieldId: '',
            operation: '',
            value: '',
          },
        ],
      };

      const newData = [...prevData];
      newData.splice(newData.length - 1, 0, newDefinition);

      return newData;
    });

    onEditModeToggled(id, true);
    setDefinitionsToRemoveOnDiscard((prev) => [...prev, id]);
  };

  const onRemoveDefinition = (id: string) => {
    setData((prevData) => prevData.filter((x) => x.id !== id));
    setDefinitionsToRemoveOnDiscard((prevData) => prevData.filter((x) => x !== id));
    onEditModeToggled(id, false);
  };

  const onUpdateDefinition = (definition: ISubAccountIdDefinition) => {
    setData((prevData) => prevData.map((x) => (x.id !== definition.id ? x : definition)));
    setDefinitionsToRemoveOnDiscard((prevData) => prevData.filter((x) => x !== definition.id));
  };

  const onEditModeToggled = (id: string, value: boolean) => {
    if (value) {
      setDefinitionsInEditMode((prev) => [...prev, id]);
    } else {
      setDefinitionsInEditMode((prev) => prev.filter((x) => x !== id));
    }
  };

  const getEditModeEnabled = useCallback(
    (id: string) => definitionsInEditMode.includes(id),
    [definitionsInEditMode],
  );

  const getRemoveOnDiscardConfig = useCallback(
    (id: string) => definitionsToRemoveOnDiscard.includes(id),
    [definitionsToRemoveOnDiscard],
  );

  const sortableDefinitions = useMemo(() => data.slice(0, -1), [data]);
  const defaultDefinition = useMemo(() => data[data.length - 1], [data]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const items = [...sortableDefinitions];
    const [removed] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, removed);

    setData([...items, defaultDefinition]);
  };

  return (
    <CustomDialog
      title='SubAccount ID Format'
      onClose={onCancel}
      open={open}
      maxWidth='lg'
      fullWidth
      actions={
        <>
          <Button variant='outlined' color='secondary' className='narrow' onClick={onAddDefinition}>
            Add
          </Button>
          <Button
            variant='contained'
            size='large'
            color='secondary'
            disabled={!!definitionsInEditMode.length}
            onClick={onSave}
          >
            Save
          </Button>
        </>
      }
    >
      <Box className={classes.root}>
        <Box className={classes.scrollableContainer}>
          <Box>
            <Typography variant='body1' className={classes.mb16}>
              Rules are applied in the order shown; each row will use the first applicable rule.
              <br />
              Drag and drop rules to reorder them as needed.
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='definitions-list'>
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    <Box className={classes.controlsList}>
                      {sortableDefinitions.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={index === data.length - 1}
                          disableInteractiveElementBlocking={index === data.length - 1}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DefinitionCard
                                fields={fields}
                                definition={item}
                                isDefault={index === data.length - 1}
                                onUpdate={onUpdateDefinition}
                                onDelete={onRemoveDefinition}
                                onEditModeToggled={onEditModeToggled}
                                editModeEnabled={getEditModeEnabled(item.id)}
                                removeOnDiscard={getRemoveOnDiscardConfig(item.id)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          {!!defaultDefinition && (
            <Box>
              <DefinitionCard
                fields={fields}
                definition={defaultDefinition}
                isDefault={true}
                onUpdate={onUpdateDefinition}
                onDelete={onRemoveDefinition}
                onEditModeToggled={onEditModeToggled}
                editModeEnabled={getEditModeEnabled(defaultDefinition.id)}
                removeOnDiscard={getRemoveOnDiscardConfig(defaultDefinition.id)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </CustomDialog>
  );
};
