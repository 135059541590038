import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  mt24: {
    marginTop: 24,
  },
  ml12: {
    marginLeft: 12,
  },
  mrn8: {
    marginLeft: 12,
  },
  root: {
    height: '60vh',

    'input[type=number]::-webkit-inner-spin-button': {
      opacity: 1,
    },
  },
  twoColumnsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
  },
  scriptContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 16,
  },
  mappingsContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 380px',
    gridGap: 16,
  },
  tabContainer: {
    height: 'calc(100% - 48px)',
  },
  scriptUploadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    overflowY: 'hidden',
    gap: 8,

    '& pre': {
      marginTop: '0 !important',
      flexGrow: 1,
      '&::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: t.palette.custom.dark[67],
        borderRadius: 4,
      },
    },
  },
  scriptLogsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 16,
    paddingBottom: 23,
    overflowY: 'hidden',
  },
  formContainer: {
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  formCard: {
    padding: 16,
    backgroundColor: t.palette.custom.dark[2],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[10],
    overflow: 'visible',
  },
  infoCard: {
    padding: 16,
    backgroundColor: t.palette.custom.dark[2],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: t.palette.custom.dark[10],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  spinnerIcon: {
    height: 30,
    cursor: 'pointer',

    animation: `${keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(90deg);
      }
      `} 2s infinite ease-in-out`,
  },
  failureIcon: {
    height: 30,
    cursor: 'pointer',
  },
  selectedCell: {
    backgroundColor: t.palette.custom.dark[5],
  },
  controlsCard: {
    flexGrow: 1,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  controls: {
    display: 'flex',
    gap: 16,
  },
  columnsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  colHeaderDivider: {
    marginTop: 4,
    marginBottom: 4,
    borderColor: t.palette.custom.dark[67],
  },
  colSubHeader: {
    color: t.palette.custom.dark[67],
  },
  assignedField: {
    '& label': {
      fontWeight: 600,
      color: t.palette.custom.dark[100],
    },

    '& .MuiInputBase-root': {
      backgroundColor: t.palette.custom.dark[5],
    },
  },
  unassignedField: {
    '& .MuiInputBase-root': {
      backgroundColor: t.palette.custom.white[100],
    },
  },
  colHeaderTopSection: { display: 'flex', alignItems: 'center', gap: 24 },
  adjustmentLabel: {
    color: t.palette.custom.info[100],
  },
  previewTable: {
    '& .MuiDataGrid-menuIcon': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      visibility: 'hidden !important' as any,
      width: 0,
      '& button': {
        width: 0,
      },
    },
  },
  infoText: {
    color: t.palette.custom.info[100],
  },
  columnTitleSection: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
}));

export default useStyles;
