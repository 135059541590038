import AdjustmentDefinitionsProvider from '@providers/AdjustmentDefinitionsProvider';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IAdjustmentDefinitionDto } from '@models/interfaces/dtos/IAdjustmentDefinitionDto';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { ICreateAdjustmentDefinitionRequest } from '@models/interfaces/requests/ICreateAdjustmentDefinitionRequest';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { IUpdateAdjustmentDefinitionRequest } from '@models/interfaces/requests/IUpdateAdjustmentDefinitionRequest';
import mapper from '@models/mapper';

const adjustmentDefinitionsProvider = new AdjustmentDefinitionsProvider();

export const getAdjustmentDefinitions = async (
  url: string,
  operation: string,
  categoryId?: string,
  type?: number,
  targetId?: string,
) => {
  const result = await adjustmentDefinitionsProvider.getAdjustmentDefinitions(
    url,
    operation,
    categoryId,
    type,
    targetId,
  );
  return {
    operation: result.operation,
    items: mapper.mapArray<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
      result.items,
      'IAdjustmentDefinitionDto',
      'IAdjustmentDefinition',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getAdjustmentDefinition = async (url: string) => {
  const result = await adjustmentDefinitionsProvider.getAdjustmentDefinition(url);
  return mapper.map<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
    result,
    'IAdjustmentDefinitionDto',
    'IAdjustmentDefinition',
  );
};

export const createAdjustmentDefinition = async (
  url: string,
  data: ICreateAdjustmentDefinitionData,
) => {
  const requestData = mapper.map<
    ICreateAdjustmentDefinitionData,
    ICreateAdjustmentDefinitionRequest
  >(data, 'ICreateAdjustmentDefinitionData', 'ICreateAdjustmentDefinitionRequest');
  const result = await adjustmentDefinitionsProvider.createAdjustmentDefinition(url, requestData);
  return mapper.map<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
    result,
    'IAdjustmentDefinitionDto',
    'IAdjustmentDefinition',
  );
};

export const updateAdjustmentDefinition = async (
  url: string,
  data: IUpdateAdjustmentDefinitionData,
) => {
  const requestData = mapper.map<
    IUpdateAdjustmentDefinitionData,
    IUpdateAdjustmentDefinitionRequest
  >(data, 'IUpdateAdjustmentDefinitionData', 'IUpdateAdjustmentDefinitionRequest');
  const result = await adjustmentDefinitionsProvider.updateAdjustmentDefinition(url, requestData);
  return mapper.map<IAdjustmentDefinitionDto, IAdjustmentDefinition>(
    result,
    'IAdjustmentDefinitionDto',
    'IAdjustmentDefinition',
  );
};

export const deleteAdjustmentDefinition = async (url: string) => {
  await adjustmentDefinitionsProvider.deleteAdjustmentDefinition(url);
  return true;
};
