export interface IBalancingNode {
  title: string;
  accountsType: number;
  balancingType: number;
  hideSource?: boolean;
  items?: IBalancingNode[];
  showFees?: boolean;
  showPremiums?: boolean;
}

const assets: IBalancingNode = {
  title: 'Assets',
  accountsType: -1,
  balancingType: 99,
  items: [
    {
      title: 'Loans',
      accountsType: 0,
      balancingType: 97,
      showFees: true,
    },
    {
      title: 'Allowance for Loan Loss',
      accountsType: 91,
      balancingType: 91,
      hideSource: true,
    },
    {
      title: 'Investments',
      accountsType: 1,
      balancingType: 98,
      showPremiums: true,
    },
    {
      title: 'Non-Interest Assets',
      accountsType: 2,
      balancingType: 103,
      hideSource: true,
    },
  ],
};

const liabilitiesAndNetWorth: IBalancingNode = {
  title: 'Liabilities and Net Worth',
  accountsType: -1,
  balancingType: 102,
  items: [
    {
      title: 'Deposits',
      accountsType: -1,
      balancingType: 106,
      items: [
        {
          title: 'Non-Maturity Deposits',
          accountsType: 5,
          balancingType: 100,
        },
        {
          title: 'Term Deposits',
          accountsType: 9,
          balancingType: 105,
        },
      ],
    },
    {
      title: 'Borrowings',
      accountsType: 4,
      balancingType: 101,
    },
    {
      title: 'Non-Int Liabilities',
      accountsType: 3,
      balancingType: 104,
      hideSource: true,
    },
    {
      title: 'Net Worth',
      accountsType: 92,
      balancingType: 92,
      hideSource: true,
    },
  ],
};

export const rootBalancingNodes = [assets, liabilitiesAndNetWorth];
