export enum TierLimitNames {
  Coupon = 'coupon',
  Seasonality = 'seasonality',
  Term = 'term',
  MaturityDate = 'maturity_date',
  Balance = 'balance',
  BalanceProgressive = 'balance_progressive',
}

export const tierLimitValueFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const getTierLimitsLabel = (
  tierLimits:
    | {
        name: string;
        lower: number;
        upper: number;
      }[]
    | null
    | undefined,
  defaultLabel = '',
) => {
  if (!tierLimits || !tierLimits.length) return defaultLabel;

  const result = tierLimits
    .map(({ name, lower, upper }) => {
      let suffixUnit = '';
      let prefixUnit = '';
      let displayName = '';

      switch (name) {
        case TierLimitNames.Coupon:
          displayName = 'Rate';
          suffixUnit = '%';
          lower /= 100;
          upper /= 100;
          break;
        case TierLimitNames.Seasonality:
          displayName = 'Age';
          suffixUnit = ' months';
          break;
        case TierLimitNames.Term:
          displayName = 'Term';
          suffixUnit = ' months';
          break;
        case TierLimitNames.MaturityDate:
          displayName = 'Matures in';
          suffixUnit = ' months';
          break;
        case TierLimitNames.Balance:
          displayName = 'Balance';
          prefixUnit = '$';
          break;
        case TierLimitNames.BalanceProgressive:
          displayName = 'Balance (Progressive)';
          prefixUnit = '$';
          break;
        default:
          return defaultLabel;
      }

      if (lower === 0 && upper !== 0) {
        return `${displayName} < ${prefixUnit}${tierLimitValueFormat.format(upper)}${suffixUnit}`;
      } else if (upper === 0 && lower !== 0) {
        return `${displayName} > ${prefixUnit}${tierLimitValueFormat.format(lower)}${suffixUnit}`;
      } else if (lower !== 0 && upper !== 0) {
        return `${displayName} ${prefixUnit}${tierLimitValueFormat.format(
          lower,
        )} - ${prefixUnit}${tierLimitValueFormat.format(upper)}${suffixUnit}`;
      }

      return defaultLabel;
    })
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  return result.join('<br />');
};
