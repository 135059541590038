import AdjustmentsProvider from '@providers/AdjustmentsProvider';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDto } from '@models/interfaces/dtos/IAdjustmentDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { ITotalAdjustment } from '@models/interfaces/entities/ITotalAdjustment';
import { ITotalAdjustmentDto } from '@models/interfaces/dtos/ITotalAdjustmentDto';
import mapper from '@models/mapper';

const adjustmentsProvider = new AdjustmentsProvider();

export const getAdjustments = async (
  url: string,
  operations: string[],
  accountIds?: string[],
  categoryId?: string,
  targetType?: number,
  sourceType?: number,
) => {
  const result = await adjustmentsProvider.getAdjustments(
    url,
    operations,
    accountIds,
    categoryId,
    targetType,
    sourceType,
  );
  return {
    operations: result.operations,
    items: mapper.mapArray<IAdjustmentDto, IAdjustment>(
      result.items,
      'IAdjustmentDto',
      'IAdjustment',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getTotalAdjustments = async (url: string) => {
  const result = await adjustmentsProvider.getTotalAdjustments(url);
  return mapper.mapArray<ITotalAdjustmentDto, ITotalAdjustment>(
    result.items,
    'ITotalAdjustmentDto',
    'ITotalAdjustment',
  );
};
