import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import ChevronDownIcon from '@assets/icons/accordion/chevron-down.svg';
import DownloadIcon from '@assets/icons/dashboard/download.svg';
import DropAllowedIcon from '@assets/icons/dashboard/drop-allowed.svg';
import DropDisallowedIcon from '@assets/icons/dashboard/drop-disallowed.svg';
import { FileCard } from '../FileCard';
import { FileSubCategoryMappingRow } from '../FileSubCategoryMappingRow';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateFileSubCategoryData } from '@models/interfaces/additional/IUpdateFileSubCategoryData';
import { IngestionResultStatuses } from '@models/enums/IngestionResultStatuses';
import { Link } from 'react-router-dom';
import { NewFileSubCategoryCard } from '../NewFileSubCategoryCard';
import clsx from 'clsx';
import useStyles from './styles';

export interface IProps {
  id?: string;
  item: IFileCategory;
  readonly?: boolean;
  clientId: string;
  productId: string;
  oldProject?: IProject;
  subCategories: IFileSubCategory[];
  addSubCategoryEnabled: boolean;
  files: IFile[];
  oldFiles: IFile[];
  onUpdateSubCategory: (subCategory: IFileSubCategory, data: IUpdateFileSubCategoryData) => void;
  onFileDragStart: (file: IFile) => void;
  onFileDragEnd: (file: IFile) => void;
  onFileCategoriesSelected: (data: { categoryId?: string; subCategoryId?: string }) => void;
  onViewPiiScanResultDetails: (file: IFile) => void;
  onDeleteFile: (file: IFile) => void;
  onDownloadFile: (file: IFile) => void;
  invalid: boolean;
  dropDisabled?: boolean;
  configureMappingEnabled?: boolean;
  onDownloadData?: (categoryId: string) => void;
}

export const FileCategoryCard = ({
  id,
  item,
  readonly,
  clientId,
  productId,
  oldProject,
  subCategories,
  addSubCategoryEnabled,
  files,
  oldFiles,
  onUpdateSubCategory,
  onFileDragStart,
  onFileDragEnd,
  onFileCategoriesSelected,
  onViewPiiScanResultDetails,
  onDeleteFile,
  onDownloadFile,
  invalid,
  dropDisabled,
  configureMappingEnabled,
  onDownloadData,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const mappedSubCategories = useMemo(() => {
    if (item.subCategoriesDisabled) return [];
    const result = subCategories.map((category) => {
      const file = files.find((x) => x.fileSubCategoryId === category.id);
      const oldFile = oldFiles.find((x) => x.fileSubCategoryId === category.id);
      return {
        category,
        file,
        oldFile,
      };
    });
    result.sort((a, b) => {
      const aNameLowercased = a.oldFile?.name.toLowerCase();
      const bNameLowercased = b.oldFile?.name.toLowerCase();
      if (!aNameLowercased) {
        return 1;
      }
      if (!bNameLowercased) {
        return -1;
      }
      return aNameLowercased > bNameLowercased ? 1 : bNameLowercased > aNameLowercased ? -1 : 0;
    });
    return result.filter(({ oldFile, file }) => oldFile || file);
  }, [subCategories, files, oldFiles, item.subCategoriesDisabled]);

  const enabledMappedSubCategoriesCount = useMemo(
    () => mappedSubCategories.filter(({ category, file }) => !category.disabled || file).length,
    [mappedSubCategories],
  );

  const disablingReason = useMemo(() => {
    if (!files.length) {
      return 'No files uploaded.';
    }
    if (files.some((x) => !x.fileSubCategoryMappingId)) {
      return 'One or more files are missing column mappings. Add mappings for all files before downloading.';
    }
    if (files.some((x) => x.ingestionResultStatus !== IngestionResultStatuses.complete)) {
      return 'Failed to import standard mapped data for one or more files. Ensure all scripts are executing successfully and that all files have correct and complete mappings.';
    }
    return null;
  }, [files, enabledMappedSubCategoriesCount]);

  const onDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDownloadData && onDownloadData(item.id);
  };

  return (
    <Accordion
      id={id}
      className={clsx([classes.root, invalid && 'invalid', dropDisabled && 'disabled'])}
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
      data-testid='file-category-card'
      onDrop={() =>
        item.subCategoriesDisabled && !dropDisabled
          ? onFileCategoriesSelected({
              categoryId: item.id,
              subCategoryId: undefined,
            })
          : undefined
      }
    >
      <AccordionSummary
        data-tour={expanded ? undefined : 'expand'}
        expandIcon={<img alt='arrow down' data-testid='expand-icon' src={ChevronDownIcon} />}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <Box className={classes.title}>
          <Box>
            {dropDisabled === true && (
              <img
                className={classes.dropStatusIcon}
                src={DropDisallowedIcon}
                alt='drop disallowed'
              />
            )}
            {dropDisabled === false && (
              <img className={classes.dropStatusIcon} src={DropAllowedIcon} alt='drop allowed' />
            )}
          </Box>
          {onDownloadData && configureMappingEnabled && !item.mappingsDisabled && (
            <Tooltip title={disablingReason || 'Download data'}>
              <IconButton
                onClick={disablingReason ? undefined : onDownload}
                className={disablingReason ? classes.disabledIconButton : undefined}
              >
                <img src={DownloadIcon} alt='download' />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant='h6'>{item.name}</Typography>
          <Typography component='div' variant='caption'>
            ({files.length}
            {!item.subCategoriesDisabled && !readonly
              ? ` of ${enabledMappedSubCategoriesCount}`
              : ''}
            {` file${files.length !== 1 ? 's' : ''}`})
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={clsx([classes.contentGrid])}>
        <Card className={classes.card} data-testid='content-section'>
          {item.subCategoriesDisabled || readonly ? (
            <Box className={classes.grid}>
              {files.map((item) => (
                <FileCard
                  key={item.id}
                  item={item}
                  extendedName
                  draggable={!!item.links[Actions.changeCategory]?.href}
                  onDetails={() => onViewPiiScanResultDetails(item)}
                  onDelete={() => onDeleteFile(item)}
                  onDownload={() => onDownloadFile(item)}
                  onDragStart={() => onFileDragStart(item)}
                  onDragEnd={() => onFileDragEnd(item)}
                />
              ))}
              {!files.length && <Typography>No files here.</Typography>}
            </Box>
          ) : (
            <Box className={classes.grid}>
              {oldProject && (
                <Box className={classes.mappingGrid}>
                  <Link
                    to={`/clients/${clientId}/products/${productId}/projects/${oldProject.id}`}
                    className={classes.previousProjectLink}
                    target='_blank'
                  >
                    <Typography variant='subtitle1' data-tour='previous-project'>
                      Previous project
                    </Typography>
                  </Link>
                </Box>
              )}
              {mappedSubCategories.map(({ category, file, oldFile }) => (
                <FileSubCategoryMappingRow
                  key={category.id}
                  category={item}
                  subCategory={category}
                  file={file}
                  oldFile={oldFile}
                  onDrop={() =>
                    !dropDisabled &&
                    onFileCategoriesSelected({ categoryId: item.id, subCategoryId: category.id })
                  }
                  onUpdateFileSubCategory={(data) => onUpdateSubCategory(category, data)}
                  onViewFilePiiScanResultDetails={onViewPiiScanResultDetails}
                  onDeleteFile={onDeleteFile}
                  onDownloadFile={onDownloadFile}
                  onFileDragStart={onFileDragStart}
                  onFileDragEnd={onFileDragEnd}
                  configureMappingEnabled={configureMappingEnabled}
                />
              ))}
              {addSubCategoryEnabled && (
                <Box className={clsx([classes.mappingGrid])}>
                  <div />
                  <div />
                  <NewFileSubCategoryCard
                    onDrop={() =>
                      !dropDisabled &&
                      onFileCategoriesSelected({
                        categoryId: item.id,
                        subCategoryId: undefined,
                      })
                    }
                  />
                </Box>
              )}
            </Box>
          )}
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};
