import { Box, Collapse, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';

import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import clsx from 'clsx';
import { useState } from 'react';
import useStyles from './styles';

export interface IProps {
  expandedByDefault: boolean;
  title: string;
  accounts: IAccount[];
  selectedAccountIds: string[];
  onAccountToggled: (accountId: string, value: boolean) => void;
}

export const AccountsGroup = ({
  expandedByDefault,
  title,
  accounts,
  selectedAccountIds,
  onAccountToggled,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(expandedByDefault);

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const isAccountChecked = (accountId: string) => {
    return selectedAccountIds.includes(accountId);
  };

  return (
    <Box className={clsx([classes.root, !expanded && 'shadowed'])}>
      <Box className={classes.header}>
        <Typography variant='h6'>{title}</Typography>
        <IconButton onClick={onToggleExpanded} className={classes.expandIcon}>
          {expanded ? (
            <img alt='arrow up' src={ChevronUpIcon} />
          ) : (
            <img alt='arrow down' src={ChevronDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Box className={classes.accountsList}>
          {accounts.map((x) => (
            <FormControlLabel
              key={x.id}
              control={
                <Switch
                  inputProps={{ role: 'switch' }}
                  checked={isAccountChecked(x.id)}
                  onChange={(_, val) => onAccountToggled(x.id, val)}
                />
              }
              label={x.accountName}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
