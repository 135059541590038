import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';

import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { formatBalance } from '../../../../utils';
import { useMemo } from 'react';
import useStyles from './styles';

interface IProps {
  adjustments: IAdjustment[];
  accounts: IAccount[];
}

export const SectionAdjustmentsTab = ({ adjustments, accounts }: IProps) => {
  const { classes } = useStyles();

  const columns = useMemo(() => {
    const baseColumns: GridColDef<IAdjustment>[] = [
      {
        field: 'balancingAccountName',
        headerName: 'Source Section',
        type: 'string',
        flex: 2,
      },
      {
        field: 'sourceTotal',
        headerName: 'Balance Sheet Total',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'sourceAdjustment',
        headerName: 'Total Adjustment',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'amount',
        headerName: 'Adjustment to Account',
        type: 'number',
        flex: 2,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];

    return baseColumns;
  }, []);

  const rows = useMemo<(IAdjustment & { balancingAccountName: string })[]>(
    () =>
      adjustments.map((x) => ({
        ...x,
        balancingAccountName:
          accounts.find((a) => a.id === x.sourceAccountId)?.accountName || 'Unknown',
      })),
    [adjustments, accounts],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={rows}
          density='compact'
          columns={columns}
          className={classes.table}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceAccountId', sort: 'asc' }],
            },
            aggregation: {
              model: {
                amount: 'sum',
              },
            },
          }}
        />
      </Box>
    </>
  );
};
