import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'end',
  },
  input: {
    maxWidth: 250,
    '& input': {
      textAlign: 'right',
    },
  },
  disabledIconButton: {
    filter: 'grayscale(1)',
    cursor: 'not-allowed',
  },
}));

export default useStyles;
