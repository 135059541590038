import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IJsonAccountData } from '@models/interfaces/additional/IJsonAccountData';
import { IUpdateAccountsDataAccount } from '@models/interfaces/additional/IUpdateAccountsData';
import { PojosMetadataMap } from '@automapper/pojos';

export const createImportAccountsMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IJsonAccountData>('IJsonAccountData', {
    SumCode: Number,
    AcctType: Number,
    ModelType: Number,
    FileID: String,
    RefRateID: String,
    RepRateID: String,
    MktRateID: String,
    NVPayRateID: String,
    NVReceiveRateID: String,
    RunoffRateId: String,
    AccountName: String,
    downloadRepricingSpread: Number,
    downloadPerCap: Number,
    downloadLTCap: Number,
    downloadLTFloor: Number,
    downloadMaxRepriceMonth: Number,
    downloadMaturity: Number,
    defaultRepriceMonth: Number,
    capCashFlows: Number,
    downloadThreeMonthBalance: Number,
    downloadThreeMonthRate: Number,
    doOverrideRepricingSpread: Boolean,
    doOverridePerCap: Boolean,
    doOverrideLTCap: Boolean,
    doOverrideLTFloor: Boolean,
    doIgnoreCapCashflow: Boolean,
    downloadRate: Number,
    doOverrideRate: Boolean,
    AccountCategory: String,
    TierGroup: String,
    doAllowExceedRate: Boolean,
    DownloadID: String,
    NeedsUpdate: Number,
    MatchID: String,
    ppmta: Number,
    PPUpdateMethod: String,
    ExcludeRO: Boolean,
    ExcludeCAT: Boolean,
    scaled: Boolean,
  });

  createMap<IJsonAccountData, IUpdateAccountsDataAccount>(
    mapper,
    'IJsonAccountData',
    'IUpdateAccountsDataAccount',
    forMember(
      (dest) => dest.fileId,
      mapFrom((src) => src.FileID),
    ),
    forMember(
      (dest) => dest.accountName,
      mapFrom((src) => src.AccountName),
    ),
    forMember(
      (dest) => dest.accountCategory,
      mapFrom((src) => src.AccountCategory),
    ),
    forMember(
      (dest) => dest.sumCode,
      mapFrom((src) => src.SumCode),
    ),
    forMember(
      (dest) => dest.downloadId,
      mapFrom((src) => src.DownloadID),
    ),
    forMember(
      (dest) => dest.accountType,
      mapFrom((src) => src.AcctType),
    ),
    forMember(
      (dest) => dest.tierGroup,
      mapFrom((src) => src.TierGroup ?? '')
    ),
  );
};
