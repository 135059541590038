import FileCategoriesProvider from '@providers/FileCategoriesProvider';
import { ICreateFileCategoryData } from '@models/interfaces/additional/ICreateFileCategoryData';
import { ICreateFileCategoryRequest } from '@models/interfaces/requests/ICreateFileCategoryRequest';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileCategoryDto } from '@models/interfaces/dtos/IFileCategoryDto';
import { ILinks } from '@models/interfaces/entities/ILinks';
import { ILinksDto } from '@models/interfaces/dtos/ILinksDto';
import { IUpdateFileCategoriesOrderData } from '@models/interfaces/additional/IUpdateFileCategoriesOrderData';
import { IUpdateFileCategoriesOrderRequest } from '@models/interfaces/requests/IUpdateFileCategoriesOrderRequest';
import { IUpdateFileCategoryData } from '@models/interfaces/additional/IUpdateFileCategoryData';
import { IUpdateFileCategoryRequest } from '@models/interfaces/requests/IUpdateFileCategoryRequest';
import mapper from '@models/mapper';

const fileCategoriesProvider = new FileCategoriesProvider();

export const getFileCategories = async (url: string) => {
  const result = await fileCategoriesProvider.getFileCategories(url);
  return {
    items: mapper.mapArray<IFileCategoryDto, IFileCategory>(
      result.items,
      'IFileCategoryDto',
      'IFileCategory',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const createFileCategory = async (url: string, data: ICreateFileCategoryData) => {
  const requestData = mapper.map<ICreateFileCategoryData, ICreateFileCategoryRequest>(
    data,
    'ICreateFileCategoryData',
    'ICreateFileCategoryRequest',
  );
  const result = await fileCategoriesProvider.createFileCategory(url, requestData);
  return mapper.map<IFileCategoryDto, IFileCategory>(result, 'IFileCategoryDto', 'IFileCategory');
};

export const updateFileCategory = async (url: string, data: IUpdateFileCategoryData) => {
  const requestData = mapper.map<IUpdateFileCategoryData, IUpdateFileCategoryRequest>(
    data,
    'IUpdateFileCategoryData',
    'IUpdateFileCategoryRequest',
  );
  const result = await fileCategoriesProvider.updateFileCategory(url, requestData);
  return mapper.map<IFileCategoryDto, IFileCategory>(result, 'IFileCategoryDto', 'IFileCategory');
};

export const getFileCategory = async (url: string) => {
  const result = await fileCategoriesProvider.getFileCategory(url);
  return mapper.map<IFileCategoryDto, IFileCategory>(result, 'IFileCategoryDto', 'IFileCategory');
};

export const deleteFileCategory = async (url: string) => {
  await fileCategoriesProvider.deleteFileCategory(url);
  return true;
};

export const updateFileCategoriesOrder = async (
  url: string,
  data: IUpdateFileCategoriesOrderData,
) => {
  const requestData = mapper.map<IUpdateFileCategoriesOrderData, IUpdateFileCategoriesOrderRequest>(
    data,
    'IUpdateFileCategoriesOrderData',
    'IUpdateFileCategoriesOrderRequest',
  );
  const result = await fileCategoriesProvider.updateFileCategoriesOrder(url, requestData);
  return {
    items: mapper.mapArray<IFileCategoryDto, IFileCategory>(
      result.items,
      'IFileCategoryDto',
      'IFileCategory',
    ),
    links: mapper.map<ILinksDto, ILinks>(result.links, 'ILinksDto', 'ILinks'),
  };
};

export const getFileCategoryScript = async (url: string) => {
  return await fileCategoriesProvider.getFileCategoryScript(url);
};

export const updateFileCategoryScript = async (url: string, data: string) => {
  const result = await fileCategoriesProvider.updateFileCategoryScript(url, data);
  return mapper.map<IFileCategoryDto, IFileCategory>(result, 'IFileCategoryDto', 'IFileCategory');
};
