import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Dropdown } from '@components/Dropdown';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import InfoIcon from '@assets/icons/dashboard/info.svg';
import { Loader } from '@components/Loader';
import { NewTypeCodeStatuses } from '@models/enums/NewTypeCodeStatuses';
import { PreviewSamplesDialog } from '../PreviewSamplesDialog';
import SaveFilledIcon from '@assets/icons/dashboard/save-filled.svg';
import XCircleRedIcon from '@assets/icons/dashboard/x-circle-red.svg';
import XIcon from '@assets/icons/dashboard/x.svg';
import { toast } from 'react-toastify';
import { updateNewTypeCode } from '@services/api';
import useStyles from './styles';

interface ITypeCodeAdditionalData {
  code: string;
  status: NewTypeCodeStatuses;
  description: string;
  additional: string;
  accountId?: string;
}

interface INewTypeCode {
  code: string;
  balance: number;
  status: NewTypeCodeStatuses;
  description: string;
  additional: string;
  accountId?: string;
}

export interface IProps {
  categoryId: string;
  accounts: IAccount[];
  typeCodes: INewTypeCode[];
  onUpdated: (typeCode: ITypeCode) => void;
  updateLink?: string;
  getSamplesLink?: string;
  isGL?: boolean;
}

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

export const NewTypeCodesGroup = ({
  categoryId,
  accounts,
  typeCodes,
  onUpdated,
  updateLink,
  getSamplesLink,
  isGL,
}: IProps) => {
  const { classes } = useStyles();

  const [additionalDataRecords, setAdditionalDataRecords] = useState<ITypeCodeAdditionalData[]>([]);
  const [invalidRecords, setInvalidRecords] = useState<string[]>([]);

  const [selectedCode, setSelectedCode] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);

  const onCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCode(undefined);
  };

  const {
    request: updateNewTypeCodeRequest,
    data: updateNewTypeCodeData,
    loading: updateNewTypeCodeLoading,
  } = useApi(updateNewTypeCode, null, {
    handleErrors: true,
  });

  useEffect(() => {
    if (updateNewTypeCodeData) {
      toast.info('Successfully updated the type code.');
      console.log({ updateNewTypeCodeData });
      onUpdated(updateNewTypeCodeData);
      setAdditionalDataRecords((prev) =>
        prev.filter((x) => x.code !== updateNewTypeCodeData.typeCode),
      );
    }
  }, [updateNewTypeCodeData]);

  const formatBalance = useCallback((value: number) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)}`;
  }, []);

  const onStatusChanged = (code: string, status: NewTypeCodeStatuses) => {
    const typeCode = typeCodes.find((x) => x.code === code);
    const record = additionalDataRecords.find((x) => x.code === code);
    if (!record && (!typeCode || status === typeCode.status)) return;
    if (status === NewTypeCodeStatuses.none && record) {
      setAdditionalDataRecords((prev) => prev.filter((x) => x.code !== code));
    } else if (status !== NewTypeCodeStatuses.none) {
      if (!record) {
        setAdditionalDataRecords((prev) => [
          ...prev,
          { code, status, description: '', additional: '', accountId: undefined },
        ]);
      } else {
        if (status === record.status) return;
        setAdditionalDataRecords((prev) =>
          prev.map((x) =>
            x.code === code
              ? { code, status, description: '', additional: '', accountId: undefined }
              : x,
          ),
        );
      }
    }
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
  };

  const onSave =
    ({ code, status, description, additional, accountId }: ITypeCodeAdditionalData) =>
    () => {
      if (!updateLink) return;
      if (!description || !additional) {
        setInvalidRecords((prev) => [...prev, code]);
      } else {
        if (status === NewTypeCodeStatuses.ignored) {
          updateNewTypeCodeRequest(updateLink, {
            code,
            description,
            ignoreReason: additional,
            isUsed: false,
          });
        } else {
          toast.error('This action is not supported yet');
        }
      }
    };

  const onShowSamples = (code: string) => {
    setSelectedCode(code);
    setOpenDialog(true);
  };

  const onCancel = (record: ITypeCodeAdditionalData) => () => {
    setAdditionalDataRecords((prev) => prev.filter((x) => x.code !== record.code));
  };

  const onEdit =
    ({ code, status, description, additional, accountId }: INewTypeCode) =>
    () => {
      setAdditionalDataRecords((prev) => [
        ...prev,
        { code, status, description, additional, accountId },
      ]);
    };

  const onChangeDescription = (code: string, value: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, description: value } : x)),
    );
  };

  const onChangeAdditional = (code: string, value: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, additional: value } : x)),
    );
  };

  const onChangeAccountId = (code: string, value?: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
    setAdditionalDataRecords((prev) =>
      prev.map((x) => (x.code === code ? { ...x, accountId: value } : x)),
    );
  };

  const clearInvalid = (code: string) => {
    setInvalidRecords((prev) => prev.filter((x) => x !== code));
  };

  const statusOptionsWithoutNone = useMemo(
    () =>
      isGL
        ? []
        : [
            {
              value: NewTypeCodeStatuses.ignored,
              label: 'Do Not Use in Simulation',
            },
            {
              value: NewTypeCodeStatuses.mappedToNewAccount,
              label: 'Create New Account',
            },
            {
              value: NewTypeCodeStatuses.mappedToExistingAccount,
              label: 'Map to Existing Account',
            },
          ],
    [isGL],
  );

  const statusOptionsWithNone = useMemo(
    () => [
      {
        value: NewTypeCodeStatuses.none,
        label: 'None',
      },
      ...statusOptionsWithoutNone,
    ],
    [statusOptionsWithoutNone],
  );

  const existingAccountOptions = useMemo<IAutocompleteOption[]>(() => {
    const accountsWithoutTierGroup = accounts
      .filter((x) => !x.isNew && !x.tierGroup)
      .map((x) => ({
        value: x.id,
        title: x.accountName,
      }));

    const uniqueAccountsWithTierGroup = accounts
      .filter((x) => !x.isNew && x.tierGroup)
      .reduce<{ titles: Set<string>; accounts: { value: string; title: string }[] }>(
        (acc, x) => {
          if (!acc.titles.has(x.tierGroup)) {
            acc.titles.add(x.tierGroup);
            acc.accounts.push({ value: x.id, title: x.tierGroup });
          }
          return acc;
        },
        { titles: new Set(), accounts: [] },
      ).accounts;

    const options = [...accountsWithoutTierGroup, ...uniqueAccountsWithTierGroup];
    options.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }));
    return options;
  }, [accounts]);

  const newAccountOptions = useMemo<IAutocompleteOption[]>(() => {
    const options = accounts
      .filter((x) => x.isNew)
      .map((x) => ({
        value: x.id,
        title: x.accountName,
      }));
    options.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }));
    return options;
  }, [accounts]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'code',
          headerName: 'Type Code',
          type: 'string',
          width: 150,
          renderCell: (params) => {
            const value = params.value;
            return (
              <Box className={classes.flexCell}>
                {value}
                {getSamplesLink && (
                  <Tooltip title='Show data samples'>
                    <IconButton onClick={() => onShowSamples(value)}>
                      <img src={InfoIcon} alt='info' />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          },
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          width: 150,
          renderCell: (params) => {
            const value = params.value || 0;
            return formatBalance(value);
          },
        },
        {
          field: 'status',
          headerName: 'Action',
          type: 'string',
          width: 220,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const record = additionalDataRecords.find((x) => x.code === params.id);
            const storedStatus = params.value;
            if (!record && storedStatus !== NewTypeCodeStatuses.none) {
              return statusOptionsWithoutNone.find((x) => x.value === params.value)?.label;
            }

            const status = record?.status || NewTypeCodeStatuses.none;
            return (
              <Dropdown
                value={status}
                onChanged={(value) =>
                  onStatusChanged(params.id.toString(), value as NewTypeCodeStatuses)
                }
                options={
                  storedStatus !== NewTypeCodeStatuses.none
                    ? statusOptionsWithoutNone
                    : statusOptionsWithNone
                }
              />
            );
          },
        },
        {
          field: 'description',
          headerName: 'Type Code Description',
          type: 'string',
          width: 190,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const record = additionalDataRecords.find((x) => x.code === params.id);
            if (!record) return params.value;

            const status = record.status || NewTypeCodeStatuses.none;
            const value = record.description || '';
            const invalid = !value && invalidRecords.includes(record.code);

            if (!record) return null;
            if (status === NewTypeCodeStatuses.none) return value;
            if (
              status === NewTypeCodeStatuses.ignored ||
              status === NewTypeCodeStatuses.mappedToNewAccount ||
              status === NewTypeCodeStatuses.mappedToExistingAccount
            ) {
              return (
                <TextField
                  value={value}
                  size='small'
                  variant='outlined'
                  placeholder={invalid ? '' : 'Description'}
                  error={invalid}
                  helperText={invalid ? 'Description is required' : ''}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={(evt) => onChangeDescription(record.code, evt.target.value)}
                  onKeyDown={(evt) => {
                    evt.stopPropagation();
                  }}
                />
              );
            }
          },
        },
        {
          field: 'additional',
          headerName: 'Additional Information',
          type: 'string',
          width: 240,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const record = additionalDataRecords.find((x) => x.code === params.id);
            if (!record) return params.value;

            const status = record.status || NewTypeCodeStatuses.none;
            const additional = record.additional || '';
            const accountId = record.accountId;
            const invalid = !additional && invalidRecords.includes(record.code);

            if (status === NewTypeCodeStatuses.none) return additional;
            if (status === NewTypeCodeStatuses.ignored) {
              return (
                <TextField
                  value={additional}
                  size='small'
                  variant='outlined'
                  placeholder={invalid ? '' : 'Reason for ignoring'}
                  error={invalid}
                  helperText={invalid ? 'Reason is required' : ''}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={(evt) => onChangeAdditional(record.code, evt.target.value)}
                  onKeyDown={(evt) => {
                    evt.stopPropagation();
                  }}
                />
              );
            } else if (status === NewTypeCodeStatuses.mappedToNewAccount) {
              const selectedAccountOption =
                newAccountOptions.find((x) => x.value === accountId) || null;

              return (
                <CustomAutocomplete
                  key={`new-account-${record.code}`}
                  allowCustomOptions
                  fullWidth
                  value={selectedAccountOption}
                  size='small'
                  variant='outlined'
                  placeholder={invalid ? '' : 'New account name'}
                  error={invalid}
                  helperText={invalid ? 'Account name is required' : ''}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={(value) => {
                    onChangeAdditional(record.code, value?.title || '');
                    onChangeAccountId(
                      record.code,
                      value ? (value.custom ? undefined : value.value) : undefined,
                    );
                  }}
                  onKeyDown={(evt) => {
                    evt.stopPropagation();
                  }}
                  options={newAccountOptions}
                  clearIcon={<img src={XIcon} alt='Clear' />}
                />
              );
            } else if (status === NewTypeCodeStatuses.mappedToExistingAccount) {
              const selectedAccountOption =
                existingAccountOptions.find((x) => x.value === accountId) || null;

              return (
                <CustomAutocomplete
                  key={`existing-account-${record.code}`}
                  fullWidth
                  value={selectedAccountOption}
                  size='small'
                  variant='outlined'
                  placeholder={invalid ? '' : 'Select Account'}
                  error={invalid}
                  helperText={invalid ? 'Account is required' : ''}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  onChange={(value) => {
                    onChangeAdditional(record.code, value?.title || '');
                    onChangeAccountId(record.code, value?.value || undefined);
                  }}
                  onKeyDown={(evt) => {
                    evt.stopPropagation();
                    clearInvalid(record.code);
                  }}
                  options={existingAccountOptions}
                  clearIcon={<img src={XIcon} alt='Clear' />}
                />
              );
            }
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'string',
          width: 70,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const record = additionalDataRecords.find((x) => x.code === params.id);
            const status = record?.status || NewTypeCodeStatuses.none;
            const storedStatus = params.row.status;
            if (!record || status === NewTypeCodeStatuses.none) {
              if (storedStatus === NewTypeCodeStatuses.none) {
                return null;
              } else {
                return [
                  <GridActionsCellItem
                    key={`${params.row.code}-edit`}
                    icon={<img src={EditIcon} alt='Edit' />}
                    label='Edit'
                    onClick={onEdit(params.row)}
                  />,
                ];
              }
            }
            return [
              <GridActionsCellItem
                key={`${record.code}-save`}
                icon={<img src={SaveFilledIcon} alt='Save' />}
                label='Save'
                onClick={onSave(record)}
              />,
              <GridActionsCellItem
                key={`${record.code}-cancel`}
                icon={<img src={XCircleRedIcon} alt='Cancel' />}
                label='Cancel'
                onClick={onCancel(record)}
              />,
            ];
          },
        },
      ] as GridColDef<INewTypeCode>[],
    [additionalDataRecords, invalidRecords, typeCodes, getSamplesLink, existingAccountOptions],
  );

  const title = useMemo(
    () => (typeCodes.length ? 'New Type Codes' : 'No New Type Codes'),
    [typeCodes],
  );

  const expanded = useMemo(() => !!typeCodes.length, [typeCodes]);

  const totalBalance = useMemo(
    () => typeCodes.reduce((acc, item) => acc + item.balance, 0),
    [typeCodes],
  );

  const showLoader = useLoader(updateNewTypeCodeLoading);

  return (
    <Accordion className={classes.root} expanded={expanded}>
      <AccordionSummary
        expandIcon={null}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <Box className={classes.title}>
          <Typography variant='subtitle2'>{title}</Typography>
        </Box>
        <Box className={classes.balanceLabel}>
          <Typography variant='caption'>Total Balance</Typography>
          <Typography variant='caption'>{formatBalance(totalBalance)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Card className={classes.card}>
          <DataGridPro
            rows={typeCodes}
            columns={columns}
            density='standard'
            className={classes.table}
            rowSelection={false}
            initialState={{
              sorting: {
                sortModel: [{ field: 'code', sort: 'asc' }],
              },
            }}
            slots={{ toolbar: CustomToolbar }}
            getRowId={(x) => x.code}
          />
        </Card>
      </AccordionDetails>
      <Loader show={showLoader} fixed={false} />
      {getSamplesLink && selectedCode && (
        <PreviewSamplesDialog
          open={openDialog}
          onClose={onCloseDialog}
          categoryId={categoryId}
          getSamplesLink={getSamplesLink}
          code={selectedCode}
        />
      )}
    </Accordion>
  );
};
