import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 16,
    paddingLeft: 16,
    backgroundColor: '#f3f3f3',
    color: t.palette.custom.dark[100],
    position: 'relative',
    zIndex: 1,

    '&.shadowed': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
      borderRadius: 4,
    },
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: 16,
    position: 'relative',
  },
  flex: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    gap: 4,
  },
  expandIcon: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  accountsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 48,
    paddingRight: 48,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    marginLeft: -16,
    marginRight: -16,
    marginBottom: -12,
  },
}));

export default useStyles;
