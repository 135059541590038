import { Box } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useMemo } from 'react';
import useStyles from './styles';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

interface IProps {
  logs: string;
}

export const LogsViewer = ({ logs }: IProps) => {
  const { classes } = useStyles();

  const logLines = useMemo(() => logs.split('\n'), [logs]);

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => (
    <Box style={style} className={classes.row}>
      <SyntaxHighlighter style={vs} language='text' showLineNumbers startingLineNumber={index + 1}>
        {logLines[index]}
      </SyntaxHighlighter>
    </Box>
  );

  return (
    <List
      style={{ height: '100%' }}
      className={classes.root}
      height={600}
      width={'100%'}
      itemCount={logLines.length}
      itemSize={18}
      overscanCount={50}
    >
      {Row}
    </List>
  );
};
