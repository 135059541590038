import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';

import ChevronDownIcon from '@assets/icons/accordion/chevron-down.svg';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
  title: string;
  typeCodes: {
    code: string;
    description: string;
    accountName?: string;
    ignoreReason?: string;
  }[];
  onToggle?: (expanded: boolean) => void;
  gridColumns: string[];
  totalBalance: number;
}

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

export const ExistingTypeCodesGroup = ({
  title,
  onToggle,
  typeCodes,
  gridColumns,
  totalBalance,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const formatBalance = useCallback((value: number) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)}`;
  }, []);

  const onChange = (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    setExpanded(expanded);
    if (onToggle) onToggle(expanded);
  };

  const rows = useMemo(() => {
    return typeCodes.map((x) => ({ ...x, id: uuidv4() }));
  }, [typeCodes]);

  const columns = useMemo(
    () =>
      [
        gridColumns.includes('Type Code')
          ? {
              field: 'code',
              headerName: 'Type Code',
              type: 'string',
              width: 200,
            }
          : null,
        gridColumns.includes('Type Code Description')
          ? {
              field: 'description',
              headerName: 'Description',
              type: 'string',
              width: 250,
            }
          : null,
        gridColumns.includes('Model Account')
          ? {
              field: 'accountName',
              headerName: 'Model Account',
              type: 'string',
              width: 250,
            }
          : null,
        gridColumns.includes('Reason')
          ? {
              field: 'ignoreReason',
              headerName: 'Reason',
              type: 'string',
              width: 250,
            }
          : null,
      ].filter((x) => !!x) as GridColDef[],
    [gridColumns],
  );

  return (
    <Accordion className={classes.root} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<img alt='arrow down' data-testid='expand-icon' src={ChevronDownIcon} />}
        classes={{
          content: classes.accordionSummaryContent,
        }}
      >
        <Box className={classes.title}>
          <Typography variant='subtitle2'>{title}</Typography>
        </Box>
        <Box className={classes.balanceLabel}>
          <Typography variant='caption'>Total Balance</Typography>
          <Typography variant='caption'>{formatBalance(totalBalance)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Card className={classes.card}>
          <DataGridPro
            rows={rows}
            columns={columns}
            density='standard'
            className={classes.table}
            initialState={{
              sorting: {
                sortModel: [{ field: 'code', sort: 'asc' }],
              },
            }}
            slots={{ toolbar: CustomToolbar }}
          />
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};
