import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  pt24: {
    paddingTop: 24,
  },
  tabPlaceholder: {
    padding: 60,
    backgroundColor: t.palette.custom.white[100],
  },
  expandButton: {
    backgroundColor: t.palette.custom.white[100],
    borderTopWidth: 0,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 2,
    paddingBottom: 2,
    borderBottomLeftRadius: 36,
    borderBottomRightRadius: 36,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',

    '&.green': {
      backgroundColor: t.palette.custom.success[50],

      '&:hover, &:active': {
        backgroundColor: t.palette.custom.success[70],
      },
    },
    '&.blue': {
      backgroundColor: t.palette.custom.info[50],

      '&:hover, &:active': {
        backgroundColor: t.palette.custom.info[70],
      },
    },
    '&.active': {
      borderColor: t.palette.custom.dark[100],
    },
    '&.disabled': {
      cursor: 'not-allowed',
      backgroundColor: t.palette.custom.dark[10],
      color: t.palette.custom.dark[50],

      '&:hover, &:active': {
        backgroundColor: t.palette.custom.dark[10],
      },
    },
  },
  expandButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandButtonsInnerContainer: {
    display: 'flex',
  },
}));

export default useStyles;
