import { Mapper, createMap } from '@automapper/core';

import { IFileSubCategoryMapping } from '@models/interfaces/entities/IFileSubCategoryMapping';
import { IFileSubCategoryMappingDto } from '@models/interfaces/dtos/IFileSubCategoryMappingDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileSubCategoryMappingMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileSubCategoryMappingDto>('IFileSubCategoryMappingDto', {
    id: String,
    name: String,
    delimiter: String,
    headerStartRow: Number,
    headerEndRow: Number,
    fileSubCategoryId: String,
    records: [Object],
    subAccountIdDefinitions: [Object],
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFileSubCategoryMapping>('IFileSubCategoryMapping', {
    id: String,
    name: String,
    delimiter: String,
    headerStartRow: Number,
    headerEndRow: Number,
    fileSubCategoryId: String,
    records: [Object],
    subAccountIdDefinitions: [Object],
    links: 'ILinks',
  });

  createMap<IFileSubCategoryMappingDto, IFileSubCategoryMapping>(
    mapper,
    'IFileSubCategoryMappingDto',
    'IFileSubCategoryMapping',
  );
};
