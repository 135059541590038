import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {},
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-virtualScroller': {
      minHeight: 36,
    },
  },
  limitedHeightTable: {
    maxHeight: 480,
  },
}));

export default useStyles;
