import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { AccountsGroup } from './components/AccountsGroup';
import { CustomDialog } from '@components/CustomDialog';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { rootBalancingNodes } from '../../common/BalancingNodes';
import useStyles from './styles';

export interface IProps {
  open: boolean;
  title: string;
  subtitle: string;
  category: string;
  accounts: IAccount[];
  selectedAccountIds: string[];
  onClose: (accountIds: string[]) => void;
}

export const AccountsDialog = ({
  open,
  title,
  subtitle,
  category,
  accounts,
  selectedAccountIds,
  onClose,
}: IProps) => {
  const { classes } = useStyles();
  const [accountIds, setAccountIds] = useState<string[]>([]);

  const onCancel = () => {
    onClose(accountIds);
  };

  const onAccountToggled = (accountId: string, value: boolean) => {
    if (value) {
      setAccountIds((prev) => [...prev, accountId]);
    } else {
      setAccountIds((prev) => prev.filter((x) => x !== accountId));
    }
  };

  useEffect(() => {
    setAccountIds(selectedAccountIds.slice());
  }, [selectedAccountIds]);

  const rootBalancingNode = useMemo(
    () => rootBalancingNodes.find((node) => node.items?.some((child) => child.title === category)),
    [category, rootBalancingNodes],
  );

  const accountCategories = useMemo(() => {
    return (
      rootBalancingNode?.items?.map((child) => {
        const nestedItems = child.items ? child.items : [child];

        const accountsList = nestedItems
          .flatMap((nestedChild) =>
            accounts.filter(
              (x) =>
                x.accountType.type === nestedChild.accountsType &&
                x.summaryCode !== 1 &&
                !x.isBalancing,
            ),
          )
          .sort((a, b) => a.accountName.localeCompare(b.accountName));

        return {
          category: child.title,
          accounts: accountsList,
        };
      }) || []
    );
  }, [rootBalancingNode, accounts]);

  if (!open) return null;

  return (
    <CustomDialog
      title={title}
      onClose={onCancel}
      open={open}
      maxWidth='sm'
      fullWidth
      actions={null}
    >
      <Box className={classes.root}>
        <Typography variant='body1' className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Box className={classes.groupsList}>
          {accountCategories.map((x) => (
            <AccountsGroup
              expandedByDefault={x.category === category}
              key={x.category}
              title={x.category}
              accounts={x.accounts}
              selectedAccountIds={accountIds}
              onAccountToggled={onAccountToggled}
            />
          ))}
        </Box>
      </Box>
    </CustomDialog>
  );
};
