import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ddd',
    padding: 14.4,

    '&>div': {
      position: 'relative',
    },

    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  row: {
    '& pre': {
      margin: '0 !important',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      overflow: 'visible !important',
      border: 'none !important',

      '& .linenumber': {
        minWidth: '42px !important',
      },
    },
  },
}));

export default useStyles;
