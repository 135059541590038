import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    maxHeight: 500,
  },
  groupsList: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  subtitle: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

export default useStyles;
