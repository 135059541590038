import { Box, Container } from '@mui/material';

import clsx from 'clsx';
import { formatBalance } from '../../../../utils';
import { getTierLimitsLabel } from '../../../../common/TierLimits';
import { useMemo } from 'react';
import useStyles from './styles';

export interface IUsedTypeCodeDetails {
  code: string;
  description: string;
  accounts: { id: string; accountName: string }[];
  totalSourceBalance: number;
  totalAdjustment: number;
  adjustmentDefinitions: {
    id: string;
    code: string;
    description: string;
    tierLimits: { name: string; lower: number; upper: number }[];
    accounts: { id: string; accountName: string }[];
    adjustment: number;
  }[];
}

interface IProps {
  details: IUsedTypeCodeDetails;
}

export const UsedTypeCodeDetails = ({ details }: IProps) => {
  const { classes } = useStyles();

  const totalAdjustmentsAmount = useMemo(
    () => details.adjustmentDefinitions.reduce((sum, a) => sum + a.adjustment, 0),
    [details.adjustmentDefinitions],
  );

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          <Box className={classes.grid}>
            <Box className={clsx([classes.header, classes.colSpan3, classes.mh26])}>
              <Box>{details.code}</Box>
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>Source Balance</Box>
            <Box className={classes.spacer} />

            <Box className={clsx([classes.header, 'alignItemsEnd'])}>Type Code Balance</Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              {formatBalance(details.totalSourceBalance)}
            </Box>
            <Box className={classes.spacer} />

            <Box className={clsx([classes.header, 'alignItemsEnd'])}>Apply To</Box>
            <Box
              className={clsx([classes.header, classes.col3, classes.textRight, 'alignItemsEnd'])}
            >
              Tier Limits
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>Amount</Box>

            {details.adjustmentDefinitions.map((x) => (
              <>
                <Box
                  className={clsx([
                    classes.pl30,
                    classes.col1,
                    'gap4',
                    classes.mh26,
                    'alignItemsEnd',
                  ])}
                  key={x.id}
                >
                  {x.accounts[0]?.accountName || 'Unknown'}
                </Box>
                <Box className={clsx([classes.col3, classes.textRight, 'alignItemsEnd'])}>
                  {x.tierLimits.length ? (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: getTierLimitsLabel(x.tierLimits),
                      }}
                    />
                  ) : (
                    'N/A'
                  )}
                </Box>
                <Box className={clsx([classes.green, classes.textRight, 'alignItemsEnd'])}>
                  {formatBalance(x.adjustment)}
                </Box>
              </>
            ))}
            <Box className={classes.spacer} />

            <Box className={classes.header}>Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.totalAdjustment)}
            </Box>
            <Box className={clsx([classes.header, classes.green, classes.col4, classes.textRight])}>
              {formatBalance(totalAdjustmentsAmount)}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
