import { UpdateAction, UpdateActionTypes } from '../..';

import { Box } from '@mui/material';
import { FilesHubCallbacksNames } from '@hubs/filesHub';
import FilesHubContext from '@contexts/FilesHubContext';
import { IFile } from '@models/interfaces/entities/IFile';
import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import { useContext } from 'react';

interface IProps {
  clientId: string;
  productId: string;
  onAddUpdateAction: (updateAction: UpdateAction) => void;
}

export const SignalREventHandler = ({ clientId, productId, onAddUpdateAction }: IProps) => {
  const filesHub = useContext(FilesHubContext);

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileUploaded,
    ({ clientId: client, fileId }) => {
      if (client === clientId) {
        onAddUpdateAction({
          type: UpdateActionTypes.addFile,
          fileId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileDeleted,
    (fileId) => {
      onAddUpdateAction({ type: UpdateActionTypes.deleteFile, fileId });
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileStatusUpdated,
    ({ clientId: client, fileId, statusMessage }) => {
      if (clientId !== client) return;
      if (
        statusMessage !== PIIScanResultStatuses.completedWithPii &&
        statusMessage !== PIIScanResultStatuses.unscanned &&
        statusMessage !== PIIScanResultStatuses.completedWithoutPii &&
        statusMessage !== PIIScanResultStatuses.failure
      ) {
        // simply update status
        onAddUpdateAction({
          type: UpdateActionTypes.updateFileStatus,
          fileId,
          file: {
            piiScanResultStatus: statusMessage,
          } as IFile,
        });
      } else {
        // reload item
        onAddUpdateAction({
          type: UpdateActionTypes.reloadFile,
          fileId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileTransformationStatusUpdated,
    ({ clientId: client, fileId, statusMessage }) => {
      if (clientId !== client) return;

      onAddUpdateAction({
        type: UpdateActionTypes.updateFileTransformationStatus,
        fileId,
        file: {
          transformationResultStatus: statusMessage,
        } as IFile,
      });
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileIngestionStatusUpdated,
    ({ clientId: client, fileId, statusMessage, message }) => {
      if (clientId !== client) return;

      console.log();

      onAddUpdateAction({
        type: UpdateActionTypes.updateFileIngestionStatus,
        fileId,
        file: {
          ingestionResultStatus: statusMessage,
          ingestionResultMessage: message,
        } as IFile,
      });
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileUpdated,
    ({ clientId: client, fileId }) => {
      if (client === clientId) {
        onAddUpdateAction({
          type: UpdateActionTypes.reloadFile,
          fileId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileTransformationResultUpdated,
    ({ clientId: client, fileId }) => {
      if (client === clientId) {
        onAddUpdateAction({
          type: UpdateActionTypes.reloadFile,
          fileId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileCleanupResultUpdated,
    ({ clientId: client, fileId }) => {
      if (client === clientId) {
        onAddUpdateAction({
          type: UpdateActionTypes.reloadFile,
          fileId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileSubCategoryCreated,
    ({ clientId: client, productId: product, subCategoryId }) => {
      if (client === clientId && product === productId) {
        onAddUpdateAction({
          type: UpdateActionTypes.addFileSubCategory,
          subCategoryId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.fileSubCategoryUpdated,
    ({ clientId: client, productId: product, subCategoryId }) => {
      if (client === clientId && product === productId) {
        onAddUpdateAction({
          type: UpdateActionTypes.reloadFileSubCategory,
          subCategoryId,
        });
      }
    },
    [],
  );

  filesHub.useSignalREffect(
    FilesHubCallbacksNames.whitelistRecordCreated,
    ({ clientId: client }) => {
      if (client === clientId) {
        onAddUpdateAction({
          type: UpdateActionTypes.reloadProjectFiles,
        });
      }
    },
    [],
  );
  return <Box />;
};
