import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';

import { Box } from '@mui/material';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { formatBalance } from '../../../../utils';
import { useMemo } from 'react';
import useStyles from './styles';

interface IPremiumAdjustment {
  id: string;
  sourceSubAccountId: string;
  bookValue: number;
  parValue: number;
  amount: number;
}

interface IProps {
  adjustments: IAdjustment[];
}

export const PremiumsTab = ({ adjustments }: IProps) => {
  const { classes } = useStyles();

  const columns = useMemo(() => {
    const baseColumns: GridColDef<IPremiumAdjustment>[] = [
      {
        field: 'sourceSubAccountId',
        headerName: 'Balance Sheet Line Item',
        type: 'string',
        flex: 2,
      },
      {
        field: 'bookValue',
        headerName: 'Book Value Balance',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'parValue',
        headerName: 'Par Value Balance',
        type: 'number',
        flex: 2,
        renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
      },
      {
        field: 'amount',
        headerName: 'Adjustment to Account',
        type: 'number',
        flex: 2,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];

    return baseColumns;
  }, []);

  const premiumAdjustments = useMemo<IPremiumAdjustment[]>(() => {
    return adjustments.map((x) => ({
      id: x.id,
      sourceSubAccountId: x.sourceSubAccountId || '',
      bookValue: x.sourceTotal,
      parValue: x.sourceTotal - x.sourceAdjustment,
      amount: x.amount,
    }));
  }, [adjustments]);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={premiumAdjustments}
          density='compact'
          columns={columns}
          className={classes.table}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
            aggregation: {
              model: {
                amount: 'sum',
              },
            },
          }}
        />
      </Box>
    </>
  );
};
