import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { getFileCategoryScript, updateFileCategoryScript } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { CustomDialog } from '@components/CustomDialog';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IUpdateFileCategoryData } from '@models/interfaces/additional/IUpdateFileCategoryData';
import { Loader } from '@components/Loader';
import SaveFilledIcon from '@assets/icons/dialog/save-filled.svg';
import { ScriptUploader } from '@components/ScriptUploader';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import useStyles from './styles';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

enum DialogTabs {
  details = 0,
  script = 1,
}

interface IProps {
  category: IFileCategory;
  open: boolean;
  onClose: (data?: IUpdateFileCategoryData) => void;
}

interface IFormData {
  name: string;
  description: string;
  filePairing: boolean;
  columnsMapping: boolean;
  subAccountMatching: boolean;
}

export const EditFileCategoryDialog = ({ category, open, onClose }: IProps) => {
  const { classes } = useStyles();

  const [savedScript, setSavedScript] = useState<string | null>(null);
  const [currentScript, setCurrentScript] = useState<string>('');
  const [activeTab, setActiveTab] = useState<DialogTabs>(DialogTabs.details);

  const { request: getFileCategoryScriptRequest, loading: getFileCategoryScriptLoading } = useApi(
    getFileCategoryScript,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data !== null) {
          setCurrentScript(data);
          setSavedScript(data);
        }
      },
    },
  );

  const {
    request: updateFileCategoryScriptRequest,
    data: updateFileCategoryScriptData,
    loading: updateFileCategoryScriptLoading,
  } = useApi(
    async (link: string, data: string) => {
      await updateFileCategoryScript(link, data);
      return data;
    },
    null,
    {
      handleErrors: true,
    },
  );

  const formDefaultData = useMemo(
    () => ({
      name: category.name,
      description: category.description,
      filePairing: !category.subCategoriesDisabled,
      columnsMapping: !category.mappingsDisabled,
      subAccountMatching: !category.subAccountMatchingDisabled,
    }),
    [category],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const watchFilePairing = form.watch('filePairing');
  const watchColumnsMapping = form.watch('columnsMapping');

  const onCancel = () => {
    onClose();
  };

  const onTabChanged = (tab: DialogTabs) => {
    setActiveTab(tab);
  };

  const onSave = () => {
    const { name, description, filePairing, columnsMapping, subAccountMatching } = form.getValues();

    onClose({
      name,
      description,
      subCategoriesDisabled: !filePairing,
      mappingsDisabled: !columnsMapping,
      subAccountMatchingDisabled: !subAccountMatching,
      position: category.position,
    });
  };

  const onScriptUploaded = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const content = event.target.result as string;
        setCurrentScript(content);
      }
    };
    reader.readAsText(file);
  };

  const onUploadScript = () => {
    if (category.links[Actions.updateScript]) {
      const data = currentScript;
      updateFileCategoryScriptRequest(category.links[Actions.updateScript].href, data);
    }
  };

  useEffect(() => {
    form.reset(formDefaultData);
    if (!open) {
      setActiveTab(DialogTabs.details);
      setSavedScript(null);
      setCurrentScript('');
    }
  }, [category, open]);

  useEffect(() => {
    if (open && activeTab === DialogTabs.script && category.links[Actions.getScript]) {
      getFileCategoryScriptRequest(category.links[Actions.getScript].href);
    }
  }, [category, open, activeTab]);

  useUpdateEffect(() => {
    if (!watchFilePairing) {
      form.setValue('columnsMapping', false);
      form.setValue('subAccountMatching', false);
    }
  }, [watchFilePairing, form]);

  useUpdateEffect(() => {
    if (!watchColumnsMapping) {
      form.setValue('subAccountMatching', false);
    }
  }, [watchColumnsMapping, form]);

  useUpdateEffect(() => {
    if (updateFileCategoryScriptData) {
      setSavedScript(updateFileCategoryScriptData);
    }
  }, [updateFileCategoryScriptData]);

  const showLoader = useLoader(getFileCategoryScriptLoading, updateFileCategoryScriptLoading);

  return (
    <CustomDialog
      title='Edit Category'
      onClose={onCancel}
      open={open}
      maxWidth='xs'
      fullWidth
      actions={
        <>
          <div />
          {activeTab === DialogTabs.details ? (
            <Button
              type='submit'
              form='form'
              variant='contained'
              color='secondary'
              size='large'
              startIcon={<img alt='add' src={SaveFilledIcon} />}
            >
              Save
            </Button>
          ) : (
            <Button
              variant='contained'
              size='large'
              color='info'
              disabled={
                !currentScript ||
                currentScript === savedScript ||
                !category.links[Actions.updateScript]
              }
              onClick={onUploadScript}
            >
              Upload
            </Button>
          )}
          <div />
        </>
      }
    >
      <Box className={classes.root}>
        <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
          <Tab
            id='details-tab'
            aria-controls='details-tab-panel'
            label={<Typography variant='subtitle1'>Details</Typography>}
            value={DialogTabs.details}
          />
          <Tab
            id='script-tab'
            aria-controls='script-tab-panel'
            label={<Typography variant='subtitle1'>Script</Typography>}
            value={DialogTabs.script}
          />
        </Tabs>
        <Box
          className={classes.tabContainer}
          role='tabpanel'
          hidden={activeTab !== DialogTabs.details}
          id='details-tab-panel'
          data-testid='details-tab-panel'
          aria-labelledby='details-tab'
        >
          <form
            id='form'
            onSubmit={form.handleSubmit(() => {
              onSave();
            })}
            noValidate
          >
            <Box>
              <Controller
                name={'name'}
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter the name',
                  },
                }}
                render={({ field: { onChange, value }, formState }) => (
                  <TextField
                    fullWidth
                    label='Name'
                    variant='standard'
                    error={!!formState.errors.name}
                    helperText={formState.errors.name?.message}
                    onChange={onChange}
                    value={value}
                    autoComplete='off'
                  />
                )}
              />
            </Box>
            <Box className={classes.marginTop24}>
              <Controller
                name={'description'}
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter the description',
                  },
                }}
                render={({ field: { onChange, value }, formState }) => (
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    label='Description'
                    variant='standard'
                    error={!!formState.errors.description}
                    helperText={formState.errors.description?.message}
                    onChange={onChange}
                    value={value}
                    autoComplete='off'
                  />
                )}
              />
            </Box>
            <Box className={classes.marginTop24}>
              <Controller
                name={'filePairing'}
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        inputProps={{ role: 'switch' }}
                        checked={value}
                        onChange={onChange}
                        className={classes.marginLeft16}
                      />
                    }
                    label='File pairing'
                  />
                )}
              />
            </Box>
            {watchFilePairing && (
              <Box className={classes.marginTop24}>
                <Controller
                  name={'columnsMapping'}
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={value}
                          onChange={onChange}
                          className={classes.marginLeft16}
                        />
                      }
                      label='Columns mapping'
                    />
                  )}
                />
              </Box>
            )}

            {watchColumnsMapping && (
              <Box className={classes.marginTop24}>
                <Controller
                  name={'subAccountMatching'}
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={value}
                          onChange={onChange}
                          className={classes.marginLeft16}
                        />
                      }
                      label='Subaccount matching'
                    />
                  )}
                />
              </Box>
            )}
          </form>
        </Box>
        <Box
          className={classes.tabContainer}
          role='tabpanel'
          hidden={activeTab !== DialogTabs.script}
          id='script-tab-panel'
          data-testid='script-tab-panel'
          aria-labelledby='script-tab'
        >
          <Box className={classes.scriptUploadingContainer}>
            {category.links[Actions.updateScript] && (
              <ScriptUploader onScriptUploaded={onScriptUploaded} />
            )}
            <SyntaxHighlighter language='python' style={vs} showLineNumbers>
              {currentScript}
            </SyntaxHighlighter>
          </Box>
        </Box>
      </Box>
      <Loader show={showLoader} fixed={false} />
    </CustomDialog>
  );
};
