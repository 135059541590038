import { DataGridPro, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CustomDialog } from '@components/CustomDialog';
import { Loader } from '@components/Loader';
import { getTypeCodeSamples } from '@services/api';
import moment from 'moment';
import useStyles from './styles';

const dateFormatRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

interface IProps {
  code: string;
  categoryId: string;
  getSamplesLink: string;
  open: boolean;
  onClose: (reload?: boolean) => void;
}

export const PreviewSamplesDialog = ({
  code,
  categoryId,
  getSamplesLink,
  open,
  onClose,
}: IProps) => {
  const { classes } = useStyles();

  const [samples, setSamples] = useState<unknown[]>([]);

  const {
    request: getTypeCodeSamplesRequest,
    data: getTypeCodeSamplesData,
    loading: getTypeCodeSamplesLoading,
  } = useApi(getTypeCodeSamples, null, {
    handleErrors: true,
  });

  const onCancel = () => {
    onClose();
  };

  useUpdateEffect(() => {
    if (getTypeCodeSamplesData) {
      setSamples(getTypeCodeSamplesData.items);
    }
  }, [getTypeCodeSamplesData]);

  useEffect(() => {
    if (open) {
      getTypeCodeSamplesRequest(getSamplesLink, categoryId, code);
    }
  }, [open, getSamplesLink, categoryId, code]);

  useEffect(() => {
    if (!open) {
      setSamples([]);
    }
  }, [open]);

  const getColumnTitle = useCallback((col: string) => {
    const formattedCol = col
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

    return formattedCol.charAt(0).toUpperCase() + formattedCol.slice(1);
  }, []);

  const columns = useMemo(() => {
    if (!samples.length) return [];

    const keys = Object.keys(samples[0] as object);
    return keys
      .filter((x) => x !== 'id' && x !== 'ingress' && x !== 'ingressId')
      .map((x) => {
        const columnTitle = getColumnTitle(x);
        const columnWidth = Math.max(columnTitle.length * 10, 80);
        return {
          field: x,
          headerName: columnTitle,
          type: 'string',
          width: columnWidth,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderCell: ({ value }: any) => {
            if (x.endsWith('Date') && typeof value === 'string' && dateFormatRegexp.test(value)) {
              return moment(value).format('MM.DD.YYYY');
            }
            return value;
          },
        };
      });
  }, [samples, getColumnTitle]);

  const showLoader = useLoader(getTypeCodeSamplesLoading);

  return (
    <CustomDialog
      title='Type Code Data Samples'
      onClose={onCancel}
      open={open}
      maxWidth='xl'
      fullWidth
      actions={<div />}
    >
      <Box className={classes.container}>
        <DataGridPro
          rows={samples as GridValidRowModel[]}
          columns={columns}
          density='compact'
          className={classes.table}
          rowSelection={false}
        />
      </Box>
      <Loader show={showLoader} fixed={false} />
    </CustomDialog>
  );
};
