import { Box, Tab, Tabs, Typography } from '@mui/material';

import { IProject } from '@models/interfaces/entities/IProject';
import { UsedTypeCodesTab } from './components/UsedTypeCodesTab';
import { useState } from 'react';
import useStyles from './styles';

export enum DetailsTabs {
  newTypeCodes = 0,
  usedTypeCodes = 1,
  ignoredTypeCodes = 2,
}

interface IProps {
  project: IProject;
  type: number;
}

export const SourceBalanceDetails = ({ project, type }: IProps) => {
  const { classes } = useStyles();

  const [activeTab, setActiveTab] = useState<DetailsTabs>(DetailsTabs.usedTypeCodes);

  const onTabChanged = (tab: DetailsTabs) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Tabs value={activeTab} onChange={(e, t) => onTabChanged(t)} aria-label='tabs'>
            <Tab
              id='new-type-codes-tab'
              aria-controls='new-type-codes-tab-panel'
              label={<Typography variant='subtitle2'>New Type Codes</Typography>}
              value={DetailsTabs.newTypeCodes}
            />
            <Tab
              id='used-type-codes-tab'
              aria-controls='used-type-codes-tab-panel'
              label={<Typography variant='subtitle2'>Used Type Codes</Typography>}
              value={DetailsTabs.usedTypeCodes}
            />
            <Tab
              id='ignored-type-codes-tab'
              aria-controls='ignored-type-codes-tab-panel'
              label={<Typography variant='subtitle2'>Ignored Type Codes</Typography>}
              value={DetailsTabs.ignoredTypeCodes}
            />
          </Tabs>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.newTypeCodes}
            id='new-type-codes-tab-panel'
            aria-labelledby='new-type-codes-tab'
          >
            <Box className={classes.tabPlaceholder}>
              <Typography>Not Implemented yet.</Typography>
            </Box>
          </Box>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.usedTypeCodes}
            id='used-type-codes-tab-panel'
            aria-labelledby='used-type-codes-tab'
          >
            <UsedTypeCodesTab project={project} type={type} />
          </Box>
          <Box
            role='tabpanel'
            hidden={activeTab !== DetailsTabs.ignoredTypeCodes}
            id='ignored-type-codes-tab-panel'
            aria-labelledby='ignored-type-codes-tab'
          >
            <Box className={classes.tabPlaceholder}>
              <Typography>Not Implemented yet.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
