import { Autocomplete, FormHelperTextProps, TextField, Tooltip, Typography } from '@mui/material';

import { Box } from '@mui/system';
import { KeyboardEventHandler } from 'react';
import { StringAvatar } from '@components/StringAvatar';
import useStyles from './styles';

export interface IAutocompleteOption {
  value: string;
  title: string;
  subtitle?: string;
  showAvatar?: boolean;
  custom?: boolean;
}

export interface IProps {
  label?: string;
  placeholder?: string;
  value: IAutocompleteOption | null;
  error?: boolean;
  helperText?: string;
  options: IAutocompleteOption[];
  onChange: (value: IAutocompleteOption | null) => void;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  disabled?: boolean;
  clearIcon?: React.ReactNode;
  variant: 'standard' | 'outlined';
  size?: 'small' | 'medium';
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  fullWidth?: boolean;
  allowCustomOptions: boolean;
  className?: string;
}

export const CustomAutocomplete = ({
  value,
  error,
  helperText,
  options,
  onChange,
  onKeyDown,
  label,
  placeholder,
  disabled,
  clearIcon,
  variant,
  size,
  FormHelperTextProps,
  fullWidth,
  allowCustomOptions,
  className,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Autocomplete
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      value={value}
      onChange={(_, option) => {
        if (typeof option === 'string') {
          onChange({ value: option, title: option, custom: true });
        } else {
          onChange(option);
        }
      }}
      onInputChange={
        allowCustomOptions
          ? (_, value) => {
              const existingOption = options.find((x) => x.title === value);
              if (!existingOption) {
                onChange({ value, title: value, custom: true });
              } else {
                onChange(existingOption);
              }
            }
          : undefined
      }
      freeSolo={allowCustomOptions}
      options={options}
      clearIcon={clearIcon}
      renderInput={(params) => (
        <Tooltip title={value?.title}>
          <TextField
            {...params}
            size={size}
            variant={variant}
            placeholder={placeholder}
            label={label}
            error={error}
            helperText={helperText}
            onKeyDown={onKeyDown}
            FormHelperTextProps={FormHelperTextProps}
          />
        </Tooltip>
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.title;
      }}
      renderOption={(props, option) => {
        return (
          <Box className={classes.optionContainer} component='li' {...props}>
            {option.showAvatar && (
              <Box className={classes.optionAvatarContainer}>
                <StringAvatar size='sm' value={option.title} />
              </Box>
            )}
            <Box className={classes.optionContent}>
              <Typography className={classes.optionTitle} variant='subtitle2'>
                {option.title}
              </Typography>
              {option.subtitle && (
                <Typography className={classes.optionSubtitle} variant='caption'>
                  {option.subtitle}
                </Typography>
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};

CustomAutocomplete.defaultProps = {
  clearIcon: false,
  variant: 'standard',
  allowCustomOptions: false,
};
