import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    height: 'calc(100vh - 272px)',
    maxHeight: 500,
    overflow: 'hidden',
  },
  scrollableContainer: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 40,

    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: t.palette.custom.dark[67],
      borderRadius: 4,
    },
  },
  controlsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
  },
  mb16: {
    marginBottom: 16,
  },
}));

export default useStyles;
