export enum AdjustmentOperation {
  Initial = 'initial',
  Manual = 'manual',
  Add = 'add',
  Subtract = 'subtract',
  Match = 'match',
  Fees = 'fees',
  Premium = 'premium',
  Balance = 'balance',
  AddBalance = 'addBalance',
  ReverseBalance = 'reverseBalance',
}

export const OperationMap: Readonly<Record<string, string>> = Object.freeze({
  [AdjustmentOperation.Initial]: 'Initial',
  [AdjustmentOperation.Manual]: 'Manual',
  [AdjustmentOperation.Add]: 'Add',
  [AdjustmentOperation.Subtract]: 'Subtract',
  [AdjustmentOperation.Match]: 'Match',
  [AdjustmentOperation.Fees]: 'Fees',
  [AdjustmentOperation.Premium]: 'Premium',
  [AdjustmentOperation.Balance]: 'Balance',
  [AdjustmentOperation.AddBalance]: 'Add',
  [AdjustmentOperation.ReverseBalance]: 'Reverse',
});
