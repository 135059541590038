import { Box, IconButton, Tooltip } from '@mui/material';

import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import useStyles from './styles';

interface IProps {
  showTableExpandSwitch: boolean;
  isTableExpanded: boolean;
  onToggleTableExpand: () => void;
}

export const StandardTableFooter = ({
  showTableExpandSwitch,
  isTableExpanded,
  onToggleTableExpand,
}: IProps) => {
  const { classes } = useStyles();

  if (!showTableExpandSwitch) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.controlsContainer}>
        {isTableExpanded ? (
          <Tooltip title='Click here to decrease the table height'>
            <IconButton onClick={onToggleTableExpand}>
              <img src={ChevronUpIcon} alt='arrow-up' />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Click here to increase the table height'>
            <IconButton onClick={onToggleTableExpand}>
              <img src={ChevronDownIcon} alt='arrow-down' />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
