import { Mapper, createMap } from '@automapper/core';

import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { ITypeCodeDto } from '@models/interfaces/dtos/ITypeCodeDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createTypeCodeMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ITypeCodeDto>('ITypeCodeDto', {
    id: String,
    typeCode: String,
    description: String,
    ignoreReason: String,
    sourceType: Object,
    balance: Number,
    isNew: Boolean,
    isKnown: Boolean,
    isUsed: Boolean,
    projectId: String,
  });

  PojosMetadataMap.create<ITypeCode>('ITypeCode', {
    id: String,
    typeCode: String,
    description: String,
    ignoreReason: String,
    sourceType: Object,
    balance: Number,
    isNew: Boolean,
    isKnown: Boolean,
    isUsed: Boolean,
    projectId: String,
  });

  createMap<ITypeCodeDto, ITypeCode>(mapper, 'ITypeCodeDto', 'ITypeCode');
};
