import { BalancingItem, IProps } from '../BalancingItem';
import { Box, Typography } from '@mui/material';

import clsx from 'clsx';
import { formatBalance } from '../../utils';
import useStyles from './styles';

export const BalancingItemWithTabs = ({
  project,
  accounts,
  balanceAdjustments,
  item,
  onAdjustmentsChanged,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={clsx([classes.tab, 'left', 'blue'])}>
        <Typography variant='subtitle1'>
          {formatBalance(item.sourceBalance + item.adjustments + item.sectionAdjustments)}
        </Typography>
      </Box>
      <Box className={clsx([classes.tab, 'right', 'green'])}>
        <Typography variant='subtitle1'>{formatBalance(item.totalBalance)}</Typography>
      </Box>
      <BalancingItem
        project={project}
        item={item}
        main
        accounts={accounts}
        balanceAdjustments={balanceAdjustments}
        onAdjustmentsChanged={onAdjustmentsChanged}
      />
    </Box>
  );
};
