import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {},
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="operation"]': {
        '&::after': {
          content: '"Total"',
          display: 'block',
        },
      },
    },
  },
  limitedHeightTable: {
    maxHeight: 480,
  },
}));

export default useStyles;
