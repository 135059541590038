import { Mapper, createMap } from '@automapper/core';

import { ITotalAdjustment } from '@models/interfaces/entities/ITotalAdjustment';
import { ITotalAdjustmentDto } from '@models/interfaces/dtos/ITotalAdjustmentDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createTotalAdjustmentMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<ITotalAdjustmentDto>('ITotalAdjustmentDto', {
    operation: String,
    targetType: Number,
    amount: Number,
    projectId: String,
  });

  PojosMetadataMap.create<ITotalAdjustment>('ITotalAdjustment', {
    operation: String,
    targetType: Number,
    amount: Number,
    projectId: String,
  });

  createMap<ITotalAdjustmentDto, ITotalAdjustment>(
    mapper,
    'ITotalAdjustmentDto',
    'ITotalAdjustment',
  );
};
